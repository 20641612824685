
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
    overflow: hidden;
    overflow-y: scroll;
}



@font-face {
  font-family: MaisonNeue;
  src: url(/static/media/medium.f85c9f93.otf);
}
@font-face {
  font-family: MaisonNeue-Mono;
  src: url(/static/media/mono.7b1ec6f3.otf);
}
@font-face {
  font-family: MaisonNeue-Light;
  src: url(/static/media/light.3d942b4f.otf);
}
@font-face {
  font-family: MaisonNeue-Demi;
  src: url(/static/media/demi.5b60868e.otf);
}
@font-face {
  font-family: MaisonNeue-Book;
  src: url(/static/media/book.8029b730.otf);
}
body {
    width: 100%;
    background-color: #10041E !important;
    height: 100vh;
    background: url(/static/media/bg.603e97f7.png);
    position: relative;
    background-size: 100% ;
    -webkit-transition: background-position 20s cubic-bezier(0, 0.57, 0.43, 1.01);
    transition: background-position 20s cubic-bezier(0, 0.57, 0.43, 1.01);
}

.section .fp-tableCell{
  height: 100% !important;
}


.loaded-logo{
  width: 100%;
  height: 100vh;
  background-color: #10041E;
  z-index: 800;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.loaded-logo > .dg {
  display: none;
}

@-webkit-keyframes zoomout{
  0%{
    background-size: 200%;
  }
  100%{
    background-size: 100%;
  }
  
}

@keyframes zoomout{
  0%{
    background-size: 200%;
  }
  100%{
    background-size: 100%;
  }
  
}

@media (min-width:800px) {
  .animate-body{
    -webkit-animation: zoomout 15s cubic-bezier(0.17, 0.59, 0.4, 0.99) both;
            animation: zoomout 15s cubic-bezier(0.17, 0.59, 0.4, 0.99) both;
  }
}

@media (max-width:800px) {
  body{
    background: url(/static/media/bg_mobile.9fdbcb52.png);
    background-size: 100% ;
  }
}

/*********************/

.loaded-logo {
  background-color: #10041E;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  border-top: 2px solid red 
}

html .slides,
.loaded-logo .slides,
html > .dg,
.loaded-logo > .dg {
  display: none;
}

@media (min-width: 1000px) {
 
  .loaded-logo > .dg {
    display: block;
  }
}

.Skeleton{
    width: 100%;
    height: 100vh;
}

.Skeleton-left{
    width: 35px;
    height: 100%;
    position: absolute;
    left: 0;
    border-right: 1px solid rgba(255,255,255,.1);
    -webkit-animation: shinny 2s ease-in-out;
            animation: shinny 2s ease-in-out;
    z-index: 100;
}

.Skeleton-bottom{
    width: 100%;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
}
.Skeleton-bottom .bottom-border{
    
    height: 35px;
    border-top: 1px solid rgba(255,255,255,.1);
    -webkit-animation: shinny 2s ease-in-out;
            animation: shinny 2s ease-in-out;
    z-index: 10;
}
.bottom-border:first-of-type{
    width: 100px;
    -webkit-transition: 1s;
    transition: 1s;
}
.bottom-border:last-of-type{
    width: 100%;
}
.border-2{
    border: none !important;
    display: -webkit-flex;
    display: flex;
}
.border-2 div{
    border-top: 1px solid rgba(255,255,255,.1);
   
}
.border-2 div:nth-child(1){
    width: calc(100% - 60px);
}
.border-2 div:nth-child(2){
    width: 42px;
    position: absolute;
    right: 0;
    border: none;
}
.border-2 div:nth-child(3){
    width: 10px;
    position: absolute;
    right: 0;
}
.section {
    padding: 60px 35px 35px 35px;
    position: relative;
}

.Skeleton-bottom span {
    width: 12%;
  position: relative;
  text-align: center;
  opacity: 0.3;
  top:0;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  font-family: MaisonNeue-Mono;
  font-size: .7vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: none;
  -webkit-animation: scrolltoSpan 1.5s ease-in;
          animation: scrolltoSpan 1.5s ease-in;
}

.widthForSpan{

    width: 50% !important;
    -webkit-transition: 1s;
    transition: 1s;
    
}

@-webkit-keyframes scrolltoSpan {
    from{
        opacity: 0;
    }
    to{
        opacity: .3;
    }
}

@keyframes scrolltoSpan {
    from{
        opacity: 0;
    }
    to{
        opacity: .3;
    }
}



/********** Responsive ************/

@media (max-width:800px) {
    
    .Skeleton-left{
        display: none;
    }
    .Skeleton-bottom{
        height: 20px !important;
    }
    .Skeleton-bottom span {
        
        -webkit-transform: translateY(-10%);
        
                transform: translateY(-10%);
        width: 50%;
        font-size: 2.4vw !important;
    }
    .bottom-border:first-of-type{
        width: 50%;
        -webkit-transition: 1s;
        transition: 1s;
    }
    .bottom-border:last-of-type{
        width: 50%;
        border-top: 1px solid rgba(255,255,255,.1) !important; 
    }
    .border-2 div{
        border:none;
       
    }
}
.Header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    z-index: 500;
}
.header-shadow{
    width: 100%;
    height: 1px ;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: -1px -29px 99px 43px rgba(0,0,0,0.34);
}
.Header-content{
    height: 35px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
}
.left-log{
    height: 100%;
    width: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    -webkit-animation: shinny 2s ease-in-out;
            animation: shinny 2s ease-in-out;
}
.Header-content img{
    width: 70px;
    max-width: 70px;
    position: relative;
    top: 100%;
    -webkit-transform: translateY(-55%) !important;
            transform: translateY(-55%) !important;
    margin: 0px 20px;
}
.right-log{
    height: 100%;
    width: calc(100% - 250px);
    
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    -webkit-animation: shinny 2s ease-in-out;
            animation: shinny 2s ease-in-out;
}
.after-dots{
    height: 100%;
    width: 12px;
    position: absolute;
    right: 0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    -webkit-animation: shinny 2s ease-in-out;
            animation: shinny 2s ease-in-out;
}
.Header-content nav{
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: .5s;
    transition: .5s;
}
.Header-content ul{
    list-style: none;
   display: none;
   -webkit-justify-content: space-around;
           justify-content: space-around;
   -webkit-align-items: center;
           align-items: center;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%,-50%);
           transform: translate(-50%,-50%);
   height: 50vh;
   width: 55%;
}
.Header-content ul li{
    width: 220px;
    height: 33px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    
    position: relative;
}

.header-dots {
    width: 30px;
    height: 20px;
    margin: 0 8px;
    position: absolute;
    top: 25px;
    right: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    z-index: 500;
    cursor: pointer;
}

.header-dots span {
    width: 7px;
    height: 7px;
    background-color: rgba(255, 255, 255, 1) ;
    border-radius: 50%;
    z-index: 150;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
}


.hovered-Header-dots span:first-of-type,.hovered-Header-dots span:last-of-type{
   width: 100%;
   position: absolute;
  display: inline-block;
   border-radius: 5px;
   height: 3px;
   background-color: rgba(255, 255, 255, 1);
   -webkit-transition: all .1s cubic-bezier(0.23, -0.01, 0.29, 0.12 ) 2s  !important ;
   transition: all .1s cubic-bezier(0.23, -0.01, 0.29, 0.12 ) 2s  !important ;
}
.hovered-Header-dots span:first-of-type{
   -webkit-transform: rotate(45deg) !important;
           transform: rotate(45deg) !important;
   -webkit-animation-fill-mode: unset !important;
           animation-fill-mode: unset !important;
   -webkit-animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 );
           animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 );
}
.hovered-Header-dots span:last-of-type{
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
    -webkit-animation-fill-mode: unset !important;
            animation-fill-mode: unset !important;
    -webkit-animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 );
            animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 );
}
.hovered-Header-dots span:nth-child(2){
   visibility: hidden;
}

.hovered-Header-dots{
    -webkit-animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 ) !important;
            animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 ) !important;
    -webkit-animation-fill-mode: both !important;
            animation-fill-mode: both !important;
}
a{
    text-align: center;
}
nav a {
    text-decoration: none;
    color: inherit;
    text-align: center;
    line-height:39px; 
    display: inline-block;
}

nav li:hover{
    -webkit-mask-image: -webkit-linear-gradient(165deg, rgba(0,0,0,.3) 30%, #000 50%, rgba(0,0,0,.3) 70%);
    -webkit-mask-size: 200%;
    -webkit-animation: shine 1.5s infinite;
            animation: shine 1.5s infinite;
}
@-webkit-keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
    }
}

.Header-Work , .Header-Expertise , .Header-About , .Header-Contact , .Header-Accueil {
    font-family: MaisonNeue;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.3px;
    text-align: left;
    color: #ffffff;
    background: transparent;
    display: block;
    opacity: 0;
    -webkit-animation-fill-mode: both !important;
            animation-fill-mode: both !important;
}
.Header-Accueil{
    -webkit-animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 3s ease forwards;
            animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 3s ease forwards;
    -webkit-animation-delay: .3s;
            animation-delay: .3s;
}
.Header-About{
    -webkit-animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 1.5s ease forwards;
            animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 1.5s ease forwards;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

.Header-Expertise{
    -webkit-animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 2s ease forwards;
            animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 2s ease forwards;
    -webkit-animation-delay: .7s;
            animation-delay: .7s;
}
.Header-Work {
    -webkit-animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 2.5s ease forwards;
            animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 2.5s ease forwards;
    -webkit-animation-delay: .9s;
            animation-delay: .9s;
}


.Header-Contact{
    -webkit-animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 3s ease forwards;
            animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 3s ease forwards;
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
}



/************** dots animation ******************/
.header-dots span:first-of-type {
    -webkit-animation: animte-header-dots 1s ease-in-out;
            animation: animte-header-dots 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.header-dots span:nth-child(2) {
    -webkit-animation: animte-header-dots 1.5s ease-in-out;
            animation: animte-header-dots 1.5s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.header-dots span:last-of-type{
    -webkit-animation: animte-header-dots 2s ease-in-out;
            animation: animte-header-dots 2s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/***********************************************/

.show-nav{
    
    background-color: rgba(13, 1, 23, .75);
    -webkit-animation: show-nav .5s ease-in-out;
            animation: show-nav .5s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-transition: .5s;
    transition: .5s;
}


.blur_body{
   
    -webkit-filter: blur(10px) !important;
   
            filter: blur(10px) !important;
    -webkit-transition: .5s !important;
    transition: .5s !important;
   /*  transition: all .2s cubic-bezier(0.17, 0.65, 0.38, 0.96) ; */
}



.hide_nav {
    -webkit-animation: hide-nav .8s ease-in-out;
            animation: hide-nav .8s ease-in-out;

}
@-webkit-keyframes show-nav {
    0% {
        height: 0vh;
        opacity: 0;
    }
    1%{
        height: 100vh;
        opacity: 0;
    }
    100%{
        height: 100vh;
        opacity: 1;
    }
}
@keyframes show-nav {
    0% {
        height: 0vh;
        opacity: 0;
    }
    1%{
        height: 100vh;
        opacity: 0;
    }
    100%{
        height: 100vh;
        opacity: 1;
    }
}

@-webkit-keyframes hide-nav {
    0% {
        height: 100vh;
        opacity: 1;
    }
    99%{
        height: 100vh;
        opacity: 0;
    }
    109%{
        height: 0vh;
        opacity: 0;
    }
}

@keyframes hide-nav {
    0% {
        height: 100vh;
        opacity: 1;
    }
    99%{
        height: 100vh;
        opacity: 0;
    }
    109%{
        height: 0vh;
        opacity: 0;
    }
}


@keyframes animte-header-dots {
    0%{
        opacity: 0;
        -webkit-transform: translateY(-150%);
                transform: translateY(-150%)
    }
    100%{
        opacity: .75;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
 @-webkit-keyframes animte-header-dots {
    0% { 
        opacity: 0;
        -webkit-transform: translateY(-150%);
     }
   
    100% { 
        opacity: .75;
        -webkit-transform:  translateY(0%); }
} 


@-webkit-keyframes link {
    from {
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
        opacity: 0;
    }
    to{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1;
    }
} 


@keyframes link {
    from {
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
        opacity: 0;
    }
    to{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1;
    }
}
@-webkit-keyframes linkOpacity {
    from {
        
        opacity: 0;
    }
    to{
        
        opacity: .8;
    }
}
@keyframes linkOpacity {
    from {
        
        opacity: 0;
    }
    to{
        
        opacity: .8;
    }
}


@-webkit-keyframes scale-dots{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0 !important;
    }
    50%{
        -webkit-transform: scale(5);
                transform: scale(5);
        opacity: 1 !important;
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1 !important;
    }
}


@keyframes scale-dots{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0 !important;
    }
    50%{
        -webkit-transform: scale(5);
                transform: scale(5);
        opacity: 1 !important;
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1 !important;
    }
}

/*********** RESPONSIVE ************/


@media (max-width:700px) {
    .Header-content{
        height: 20px;
    }
    .Header-content img{
        width: 60px
    }
    .header-dots {
        width: 30px;
       top: 10px;
    }
    .left-log{
        width: 30px; 
    }
    .right-log{
        width: calc(100% - 190px); 
    }
    .after-dots{
        width: 8px;
        
    }
    nav ul{
        -webkit-flex-direction: column;
                flex-direction: column;
        top: 40% !important;
    }
    .hovered-Header-dots{
        top: 29px;
    }
}

@media (max-width:800px) {
    nav ul{
       width: 100% !important;
   }
}

.Container {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}
#fullpage{
    height: 200% !important;
}
.Paginate {
    width: 35px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
}
.Paginate-dots {
    width: 20px;
    height: 150px;
    position: relative;
    padding: 10px 0px;
    left:0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
.Paginate-dots span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 auto;
    background-color: rgba(255,255,255 ,.2);
    cursor: pointer;
}

.paginate-space-1,.paginate-space-2,.paginate-space-3,.paginate-space-dots-1{
    width: 100%;
    border-left: 1px solid rgba(255,255,255 ,.1);
    -webkit-animation: shinny 2s ease-in-out;
            animation: shinny 2s ease-in-out;
}
.paginate-space-dots-1{
    height: 22px !important;
}
.paginate-space-dots-2{
    height: 26px !important;
}

.dots-centered{
    position: relative;
    height: calc(100% - 112px) !important;
}
.paginate-space-1{
    height: calc(50% - 75px) !important;
}
.paginate-space-2{
    height:  calc(50% - 75px)!important;
}


.paginate-space-3{
    position: absolute;
    bottom: 0;
    height: 12px !important;
}

#p-triangle2 {
    display: none;
}


 .Paginate-triangle {
    width: 35px;
    height: 35px;
    margin: 10px 0px;
    border-radius: 50%;
    position: relative;
    left: 0;
    bottom: 2px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}



/****************/


/****************/
.last-dot {
    position: relative;
}
.last-dot::before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(255,255,255 ,1);
}

.selected-dot {
    border: 1px solid white;
    background-color: unset !important;
}

/********** Responsive ************/

@media (max-width:800px) {
    
    .Paginate {
        display: none;
    }
    
}
.div{
    width: 100%;
    height: 100%;
    padding-left: 4.2%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    top: 0;
    left: 0;
} 
.div h1{
    font-family: MaisonNeue;
    font-size: 5vw;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    overflow: hidden;
}
.fixed{ 
    display: block;
    overflow: hidden;
    height: auto;
 }
.fixed span{ 
    -webkit-transform: translateY(100%); 
            transform: translateY(100%);
    opacity: 1;
    height: auto;
}

.position-reverse {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: .25;
}
.expertise-animate {
    -webkit-animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
            animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
.expertise-animate-2 {
    -webkit-animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
            animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-delay: .15s;
            animation-delay: .15s;
}
.expertise-animate-3 {
    -webkit-animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
            animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-delay: .3s;
            animation-delay: .3s;
}
.expertise-animate-reverse{
    -webkit-animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
            animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
.expertise-animate-reverse-2{
    -webkit-animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
            animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-delay: .15s;
            animation-delay: .15s;
}
.expertise-animate-reverse-3{
    -webkit-animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
            animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-delay: .3s;
            animation-delay: .3s;
}
.fixed span.text-flip  {
    display: inline-block;
    
    -webkit-animation: text-flip 2s cubic-bezier(0.14, 0.6, 0.24, 1) , text-opacity 1.25s cubic-bezier(0.14, 0.6, 0.24, 1);
    
            animation: text-flip 2s cubic-bezier(0.14, 0.6, 0.24, 1) , text-opacity 1.25s cubic-bezier(0.14, 0.6, 0.24, 1);
    -webkit-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
}
.fixed span.text-flip-reverse {
    display: inline-block;
    
    -webkit-animation: text-flip-reverse 2s cubic-bezier(0.14, 0.6, 0.24, 1) , text-opacity 1.25s cubic-bezier(0.14, 0.6, 0.24, 1);
    
            animation: text-flip-reverse 2s cubic-bezier(0.14, 0.6, 0.24, 1) , text-opacity 1.25s cubic-bezier(0.14, 0.6, 0.24, 1);
    -webkit-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
}

.div p.paragraph-disapear {
    -webkit-animation: paragraphe 1s ease-in-out forwards;
            animation: paragraphe 1s ease-in-out forwards;
    -webkit-animation-delay: .8s;
            animation-delay: .8s;
}
.home-1 p,.home-2 p{
    width: 30%;
    margin:30px 0; 
    opacity: 0;
    font-family: MaisonNeue-Light;
    font-size: 1.2vw ;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}


.bgImg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: 60vw 37vw !important;
    background-size: auto;
    background-repeat: no-repeat !important;
    background-position: right 0px bottom 0px !important; 
    
}

.animateBg{
    -webkit-animation: slide 1.2s ease-in-out ;
            animation: slide 1.2s ease-in-out ;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}



/**************** Expertise Home *****************/
.home-3 {
    padding: 60px 35px 60px 35px !important;
}
.home-3 .div {
    padding: 0 !important;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.Expertise{
    width: 91%;
    margin:  0 auto;
    height: 36vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.Expertise .Exp_block{
    width: 33.33%;
    height: 50%;
    padding: 7%;
    
    position: relative;

}

.exp_block_hovered span.top-left,
.exp_block_hovered span.bottom-right,
.exp_block_hovered span.bottom-left,
.exp_block_hovered span.top-right{
    width: calc(100% + 4px) !important;
    height: calc(100% + 3px) !important;
    -webkit-transition: width 2s cubic-bezier(.16,.43,.42,.84) , height 2s cubic-bezier(.16,.43,.42,.84) !important;
    transition: width 2s cubic-bezier(.16,.43,.42,.84) , height 2s cubic-bezier(.16,.43,.42,.84) !important;
}
.exp_block_hovered span.interface_top,
.exp_block_hovered span.marketing_bottom{
    width: calc(100% + 4px) !important;
    -webkit-transition: width 2s cubic-bezier(.16,.43,.42,.84) , height 2s cubic-bezier(.16,.43,.42,.84) !important;
    transition: width 2s cubic-bezier(.16,.43,.42,.84) , height 2s cubic-bezier(.16,.43,.42,.84) !important;
}

.Exp_block span {
    position: absolute;
    height: 16px;
    width: 16px;
    opacity: 1;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    
}

.Exp_block .top-left{
    left: -2px;
    top: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.Exp_block .top-right{
    right: -2px;
    top: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.Exp_block .bottom-left{
    left: -2px;
    bottom: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}
.Exp_block .bottom-right{
    right:  -2px;
    bottom: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.Exp_block:hover span {
    width: calc(100% + 4px);
    height: calc(100% + 3px);
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}

.Interface:hover span.bottom-left , .Interface:hover span.bottom-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.Marketing:hover span.top-left , .Marketing:hover span.top-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.Exp_block:hover span.marketing_bottom {
    width: calc(100% + 3.5px) !important;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}
.Exp_block:hover span.interface_top {
    width: calc(100% + 3.5px) !important;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}
.Exp_block .marketing_bottom{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    bottom: -2px !important;
    right: 50% !important;
    -webkit-transform: translateX(50%) !important;
            transform: translateX(50%) !important;
}

.Exp_block .interface_top{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    top: -2px !important;
    right: 50% !important;
    -webkit-transform: translateX(50%) !important;
            transform: translateX(50%) !important;
}
.Expertise h4 { 
    opacity: 0.8;
  font-family: MaisonNeue;
  font-size: 1.1vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 15px;
  -webkit-transition: 1s;
  transition: 1s;
}
.Expertise div:hover h4{
    -webkit-mask-image: -webkit-linear-gradient(165deg, rgba(0,0,0,.3) 30%, #000 50%, rgba(0,0,0,.3) 70%);
    -webkit-mask-size: 200%;
    -webkit-animation: shine 2s infinite;
            animation: shine 2s infinite;
}
@-webkit-keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
    }
}
.Expertise p{ 
    opacity: 0.3;
    font-family: MaisonNeue-Mono;
    font-size: .8vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 1px 0;
    -webkit-transition: 1s;
    transition: 1s;
}
.Exp_block:hover h4{
    opacity: .9;
    -webkit-transition: 1s;
    transition: 1s;
}

.Exp_block:hover p{
    opacity: 0.8;
    -webkit-transition: 1s;
    transition: 1s;
}

.right_bottom {
    border-bottom: 1px solid rgba(255,255,255,.3);
    border-right: 1px solid rgba(255,255,255,.3);
}
.right {
    
    border-right: 1px solid rgba(255,255,255,.3);
}
.bottom {
    border-bottom: 1px solid rgba(255,255,255,.3);
}
/**************** End Expertise Home *****************/

/*************  Animation   ******************/

@-webkit-keyframes slide {
    from { 
        
        -webkit-transform: translateX(200px); 
        
                transform: translateX(200px);
         }
    to { 
        
        -webkit-transform: translateX(0px); 
        
                transform: translateX(0px);
        }
}

@keyframes slide {
    from { 
        
        -webkit-transform: translateX(200px); 
        
                transform: translateX(200px);
         }
    to { 
        
        -webkit-transform: translateX(0px); 
        
                transform: translateX(0px);
        }
}

@-webkit-keyframes text-opacity {
    from { 
        opacity: .5;
        }
    to { 
        opacity: 1;
    }
}

@keyframes text-opacity {
    from { 
        opacity: .5;
        }
    to { 
        opacity: 1;
    }
}

@-webkit-keyframes text-flip {
    0%{
        opacity: 0.2;
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
    
    100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes text-flip {
    0%{
        opacity: 0.2;
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }
    
    100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
@-webkit-keyframes expertise-animate {
    0%{
      -webkit-transform: translateY(300%);
              transform: translateY(300%);
      opacity: 1 !important;
    }
    
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1 !important;
    }
}
@keyframes expertise-animate {
    0%{
      -webkit-transform: translateY(300%);
              transform: translateY(300%);
      opacity: 1 !important;
    }
    
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1 !important;
    }
}
@-webkit-keyframes expertise-animate-reverse {
    0%{
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }
    
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
@keyframes expertise-animate-reverse {
    0%{
      -webkit-transform: translateY(-300%);
              transform: translateY(-300%);
    }
    
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@-webkit-keyframes text-flip-reverse {
    0%{
        opacity: 0.2;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }
    
    100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes text-flip-reverse {
    0%{
        opacity: 0.2;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }
    
    100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@-webkit-keyframes paragraphe {
    0%{
       opacity: 0;
    }
    100%{
        opacity: 0.5;
    }
}

@keyframes paragraphe {
    0%{
       opacity: 0;
    }
    100%{
        opacity: 0.5;
    }
}


@-webkit-keyframes opacity{
    0%{
        opacity: 1;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
}


@keyframes opacity{
    0%{
        opacity: 1;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
}
/*************  End of Animation   ******************/

/********* RESPONSIVE *******/
@media (max-width:1000px) {
    .home-1 h1,.home-2 h1{
        font-size: 6vw;
    }
    .home-1 p,.home-2 p{
        width: 350px;
        font-size: 1.4vw;
    }
    .home-1 .Button,.home-2 .Button{
        width: 120px;
        height: 30px;
        font-size: 1.4vw;
    }
    .home-1 .Button a,.home-2 .Button a{
        line-height: 30px;
    }
    .home-2 .bgImg{
        background-size: 80vw 50vw !important;
    }
}
@media (max-height:700px) {
    .home-1 p{
        margin:3% 0; 
    }
    .home-2 p{
        margin:3% 0; 
    }
}
@media (min-height:900px) {
    .clientsReviews{
        
        height :65% !important;
        
    }
}
@media (max-width:700px) {
    

    /******* EXPERTISE ******/
    .section{
        padding: 0px  !important;
    }
    .Expertise{
        width: 87% !important;
        margin:  0 auto;
        height: 90%;
        position: relative;
        top: 50%;
        
        -webkit-transform: translateY(-50%) !important;
        
                transform: translateY(-50%) !important;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-align-items: center !important;
                align-items: center !important;
        -webkit-align-content: flex-start;
                align-content: flex-start;
    }
    
    .Expertise .Exp_block{
        width: 50% !important;
        height: 30% !important;
       padding: 9% 0% 0% 7% !important;
        border: none !important;
    }
    .Expertise .Exp_block span{
        display: none;
    }
    .Expertise h4 { 
        opacity: 0.8;
        font-family: MaisonNeue;
        font-size: 3vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 15px;
    }
    .Expertise p{ 
        opacity: 0.3;
        font-family: MaisonNeue-Mono;
        font-size: 2.5vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 1px 0;
    }
    .home-1 h1,.home-2 h1{
        font-size: 7vw;
        line-height: 40px;
    }
    .home-1 .div,.home-2 .div{
        padding-left: 12%;
    }
    .home-1 p,.home-2 p{
        width: 70%;
        font-size: 3.2vw;
        margin:8% 0; 
    }
    .home-1 .Button,.home-2 .Button{
        width: 100px;
        font-size: 1.1vw;
    }
    .home-2 .bgImg{
        background-size: 90vw 100vw !important;
    }
}
@media (max-width:500px) {
   
    
    .Expertise{
        width: 100%;
       
    }
}
.Button{
    width: 9vw;
    height: 2.5vw;
    border: 1px solid rgba(255, 255, 255, .1);
    
    position: relative;
}

.Button a {
    text-decoration: none;
    font-family: MaisonNeue;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
line-height: 2.5vw;
position: absolute;
top: 0;
left: 0;
cursor: pointer;
}


.Button span {
    position: absolute;
    height: 10px;
    width: 10px;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}

.Button .top-left{
    left: -2px;
    top: -2px; 
    border-left: 1px solid rgba(255, 255, 255, .3);
    border-top: 1px solid rgba(255, 255, 255, .3);
}
.Button .top-right{
    right: -2px;
    top: -2px; 
    border-right: 1px solid rgba(255, 255, 255, .3);
    border-top: 1px solid rgba(255, 255, 255, .3);
}
.Button .bottom-left{
    left: -2px;
    bottom: -2px; 
    border-left: 1px solid rgba(255, 255, 255, .3);
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}
.Button .bottom-right{
    right:  -2px;
    bottom: -2px; 
    border-right: 1px solid rgba(255, 255, 255, .3);
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.Button:hover span {
    width: 100%;
    height: 100%;
    border-color: rgba(255, 255, 255, 1);
    -webkit-transition: width 1s cubic-bezier(.16,.43,.42,.84) , height 1s cubic-bezier(.16,.43,.42,.84);
    transition: width 1s cubic-bezier(.16,.43,.42,.84) , height 1s cubic-bezier(.16,.43,.42,.84);
}

@media (max-width:500px) {
    .Button span{
        width: 7px;
        height: 7px;
    }
}
.socialMedia {
    width: 285px;
    height: auto;
    z-index: 100;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    position: relative;
    top: -14px;
    padding: 0 20px;
    -webkit-animation: scrolltoSocialMedia 1.5s ease-in;
            animation: scrolltoSocialMedia 1.5s ease-in;
}
.socialMedia a {
    position: relative;
    margin-right: 5px;

}
.anchorAnimate img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}


.anchorAnimate img:hover{
    border-width: 1px;
    border-style: solid;
    -webkit-animation: sk-bounce 1s ease forwards;
            animation: sk-bounce 1s ease forwards;
}


@-webkit-keyframes sk-bounce {
    0%  { border-color: rgba(255, 255, 255, 0) }
    100% { border-color: rgba(255, 255, 255, .3)  }
  }


@keyframes sk-bounce {
    0%  { border-color: rgba(255, 255, 255, 0) }
    100% { border-color: rgba(255, 255, 255, .3)  }
  }
  


.animateSocialMediaReverse{
    display: none !important;
}

@-webkit-keyframes scrolltoSocialMedia {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes scrolltoSocialMedia {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@-webkit-keyframes animate-social-media {
    from{
        z-index: 100;
        opacity: 0;
    }
    to{
        z-index: 500;
        opacity: 1;
    }
}

@keyframes animate-social-media {
    from{
        z-index: 100;
        opacity: 0;
    }
    to{
        z-index: 500;
        opacity: 1;
    }
}


/********** Responsive ************/

@media (max-width:700px) {
    
    .socialMedia {
        padding: 0;
        top: -10px !important;
    }
    .socialMedia img{
        width: 22px;
        height: 22px;
    }
}
.home-4 .div{
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.clientsReviews{
    width:91%; 
    height :75%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
}

@media (max-width:700px) {
    .clientsReviews{
        width:80%; 
        height :90%;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
    .home-4 .div{
        -webkit-align-items: unset !important;
                align-items: unset !important;
    }
}
.clients{
    width: 50%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    /*
    padding-left: 2%;
    padding-right: 2%;
    */
}

.clients-slide {
    width: 100%;
    height: 66.66%;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}
.clients-slide div {
    width: 33.33%;
    height: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}
.clients-slide div span {
    position: absolute;
    height: 16px;
    width: 16px;
    opacity: 1;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    
}

.clients-slide div .top-left{
    left: -2px;
    top: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.clients-slide div .top-right{
    right: -2px;
    top: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.clients-slide div .bottom-left{
    left: -2px;
    bottom: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}
.clients-slide div .bottom-right{
    right:  -2px;
    bottom: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.clients-slide div:hover span {
    width: calc(100% + 4px);
    height: calc(100% + 3px);
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   
}
.clients-slide div:hover img{
    -webkit-mask-image: -webkit-linear-gradient(165deg, rgba(0,0,0,.3) 30%, #000 50%, rgba(0,0,0,.3) 70%);
    -webkit-mask-size: 200%;
    -webkit-animation: shine 2s infinite;
            animation: shine 2s infinite;
}
.tweak-1:hover span.bottom-left , .tweak-1:hover span.bottom-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.tweak-2:hover span.top-left , .tweak-2:hover span.top-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.clients-slide div .marketing_bottom{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    bottom: -2px !important;
    left: 50% !important;
    -webkit-transform: translateX(-50.3%) !important;
            transform: translateX(-50.3%) !important;
}

.clients-slide div .interface_top{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    top: -2px !important;
    left: 50% !important;
    -webkit-transform: translateX(-50.3%) !important;
            transform: translateX(-50.3%) !important;
}
.clients-slide div:hover span.marketing_bottom {
    width: calc(100% + 3.9px) !important;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}
.clients-slide div:hover span.interface_top {
    width: calc(100% + 3.7px) !important;
    -webkit-transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}

.clients-slide img {
    width: 100%;
    height: auto;
    opacity: .5;
    -webkit-transition: 1s;
    transition: 1s;
} 

.clients-slide div:hover img {
    opacity: 1;
    -webkit-transition: 1s;
    transition: 1s;
}

.slider{
    display: none !important;
}
.slider-list {
    height: 100px !important;
    margin: 0 !important;
}

.carousel-item{
    width: 95% !important;
    height: 100px !important;
    position: absolute !important;
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.carousel-item a {
    width: 20vw;
    height: 20vw;
    display: block;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.08);
}
.carousel-item a img{
    width: 100%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

#nike,#coca,#zurich,#macdo{
    width: 60% !important;
}
#nike,#zurich{
    -webkit-transform: translateY(-60%) !important;
            transform: translateY(-60%) !important;
}
.img-client{
    width: 80% !important;
}

@media (max-width:700px) {
    .clients-slide{
        display: none !important;
    }
    .slider{
        display: block !important;
        width: 90% !important;
       height: 100% !important;
       margin:  0 auto;
    }
    .clients{
        width: 100%;
        height: 30%;
        border: none;
        padding: 0% !important;
        padding-top: 5% !important;
    }
    
}

@media (min-height:950px){
    .carousel-item{
        height: 180px !important;
    }
    .home-4 .div{
     /* padding: 5% 0% 0% 0%!important; */
    }
}
.reviews{
    width: 50%;
    height: 80%;
    position: relative !important;
    padding-left:8%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
 
.review{
    width: 100%;
    height: 70%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}



.review .thumbnail {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}


.thumbnail h3 {
    font-family: MaisonNeue;
    font-size: 1.5vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.thumbnail span {
    opacity: 0.5;
    font-family: MaisonNeue;
    font-size: 1.2vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.review .about {
    width: 75%;
    height: 50%;
} 

.about p {
   height: auto;
   margin-top: 6% ;
    opacity: 0.3;
    font-family: MaisonNeue-Light;
    font-size: 1vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: #ffffff;
    

}


.about img {
    width: 120px;
    position: absolute;
    bottom: 15%;
}
.review-controls {
    width: 80px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.next , .prev{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}

.next img {
    width: 30px;
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
}
.prev img {
    width: 30px;
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
}


.thumbnail_animation {
    -webkit-animation: paragraph 1s ease;
            animation: paragraph 1s ease;
}
@-webkit-keyframes paragraph {
    0%{
        -webkit-transform: translateY(50%);
                transform: translateY(50%);
        opacity: 0.1 !important;
    }
    
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: .3 !important;
    }
}
@keyframes paragraph {
    0%{
        -webkit-transform: translateY(50%);
                transform: translateY(50%);
        opacity: 0.1 !important;
    }
    
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: .3 !important;
    }
}

@media (max-width:700px) {
    .reviews{
        width: 100%;
        height: 65%;
        position: relative !important;
        padding-left:5%;
        top: 0;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
    .review{
        width: 100%;
        height: 68%;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    
    .thumbnail h3 {
        font-size: 4.5vw;
    }
    .thumbnail span {
        font-size: 3vw;
    }
    .review .about {
        width: 95%;
        height: 45%;
    } 
    
    .about p {
        font-size: 3vw;
        height: 100%;
    }
    .review-controls {
        width: 80px;
        height: 30px;
        position: absolute;
        left: 3% !important;
        
    }
    .about img {
        width: 30%;
        position: relative;
    }
}
.home-5 .div{
    padding: 0!important;
    resize: none;
}

.aboutTest{
    background-color: #28223b !important;
}

.onFocus{
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative !important;
    top: 50px;
    left: 0 !important;
}
.onBlur{
    width: 100%;
    height: 100%;
    padding-left: 5% !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
}


.selectedText {
    -webkit-text-fill-color: rgba(248, 247, 247, 0.8) !important;
}
.contact{
    width: 100%;
    margin: 0 auto;
    height: 28vw;
    display: -webkit-flex;
    display: flex;
}
.in-Touch{
    width: 50%;
    height: 100%;
    padding-left: 4.2%;
    position: relative;
}

.in-Touch h1{
  font-family: MaisonNeue;
  font-size: 3.9vw;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.info span{
  font-family: MaisonNeue;
  font-size: 1.4vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  opacity: 0;
}
.in-Touch p{
  width: 100%;
  opacity: 0.5;
  font-family: MaisonNeue;
  font-size: 1vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 10px 0;
  opacity: 0;
}
.in-Touch .info{
    margin: 5% 0;
}
.in-Touch .GetLocation{
    position: absolute !important;
    bottom: 3px !important;
    left: 9%;
    opacity: 0;
    
}
.in-Touch .BtnContact{
    display: none !important;
    position: absolute !important;
    bottom: 3px !important;
    left: 40%;
    opacity: 0;
    
}

/********** form ************/

.form {
    width: 50%;
    height: 100%;
    padding-left: 8%;
    padding-top: 2%;
    position: relative;
    
}

.form .Button{
    position: absolute !important;
    bottom: 1px !important;
    left: 1px;
    margin-left: 16.5%;
    opacity: 0;
}
.form-control label{
    font-family: MaisonNeue;
    font-size:1.2vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: block;
    opacity: 0;
}
.form-control input, .form-control textarea{
    width: 80%;
    height: 30px;
    outline: none;
    margin-top: 1.2%;
    margin-bottom: 4%;
    border: none;
    background: transparent !important;
    border-bottom: 1px solid rgba(255,255,255,.1);
    opacity: 0;
    font-family: MaisonNeue;
    font-size: 1vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    -webkit-text-fill-color: rgba(248, 247, 247, 0.2);
    caret-color : rgba(248, 247, 247, 0.76);
}
/* input:focus , textarea:focus{
    background-color: transparent !important;
    color: transparent !important;
} */
input:focus::-webkit-input-placeholder{
    color: transparent!important;
}
input:focus::-moz-placeholder{
    color: transparent!important;
}
input:focus:-moz-placeholder{
    color: transparent!important;
}
.form-control textarea{
    height: 6.5vw;
    resize: none;
}

.animate-intouch * {
    -webkit-animation-fill-mode: both !important;
            animation-fill-mode: both !important;
}


.animate-intouch .info > span {
    -webkit-animation: button .7s ease-in-out ;
            animation: button .7s ease-in-out ;
    -webkit-animation-delay: .2s;
            animation-delay: .2s;
}
.animate-intouch .info > p {
    -webkit-animation: animate-p-inTouch .7s ease-in-out ;
            animation: animate-p-inTouch .7s ease-in-out ;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
}

.animate-intouch .Button {
    -webkit-animation: button 1s ease-in-out;
            animation: button 1s ease-in-out;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.animate-form * {
    -webkit-animation-fill-mode: both !important;
            animation-fill-mode: both !important;
}
.animate-form .form-control:first-of-type label{
    -webkit-animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out ;
            animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out ;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
}
.animate-form .form-control:first-of-type input{
    -webkit-animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
            animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
    -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
}
.animate-form .form-control:nth-child(2) label{
    -webkit-animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
            animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}
.animate-form .form-control:nth-child(2) input{
    -webkit-animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
            animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
}
.animate-form .form-control:nth-child(3) label{
    -webkit-animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out ;
            animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out ;
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
}
.animate-form .form-control:nth-child(3) textarea{
    -webkit-animation: animateForm .4s cubic-bezier(0.14, 0.6, 0.24, 1) ,button 1.5s  ease-in-out;
            animation: animateForm .4s cubic-bezier(0.14, 0.6, 0.24, 1) ,button 1.5s  ease-in-out;
    -webkit-animation-delay: 0.35s;
            animation-delay: 0.35s;
}

.animate-form .Button {
    -webkit-animation: button 1s ease-in-out;
            animation: button 1s ease-in-out;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

/********** Animation ***********/
@-webkit-keyframes animate-p-inTouch {
    from{
        opacity: 0;
    }
    to{
        opacity: 0.5;
    }
}
@keyframes animate-p-inTouch {
    from{
        opacity: 0;
    }
    to{
        opacity: 0.5;
    }
}
@-webkit-keyframes animate-inTouch {
    from{
        opacity: 0;
        -webkit-transform: translateY(2000%);
                transform: translateY(2000%);
    }
    to{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
@keyframes animate-inTouch {
    from{
        opacity: 0;
        -webkit-transform: translateY(2000%);
                transform: translateY(2000%);
    }
    to{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
@-webkit-keyframes animateForm {
    from{
        opacity: 0;
        -webkit-transform: translateY(2000%);
                transform: translateY(2000%);
    }
    to{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
@keyframes animateForm {
    from{
        opacity: 0;
        -webkit-transform: translateY(2000%);
                transform: translateY(2000%);
    }
    to{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}
@-webkit-keyframes button {
    form{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes button {
    form{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

/********* RESPONSIVE *******/

 
@media (max-width:800px) {
    .contact{
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 95%;
        width: 95%;
        padding-left:4%!important;
        overflow: unset !important;
    }
    
    .in-Touch{
        width: 95%;
        height: 70%;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    .in-Touch h1{
        font-size: 12vw;
    }
    .info{
        width: 100%;
        margin-top: 10% !important;
    }
    .info span{
        font-size: 6.5vw;
    }
    .info p{
        font-size: 3.5vw;
    }
    .in-Touch .GetLocation{
        width: 28vw !important;
        height: 7.5vw !important;
        position: absolute !important;
        bottom: 5% ;
        left: 5% !important;
    }
    .in-Touch .BtnContact{
        display: block !important;
        width: 28vw !important;
        height: 7.5vw !important;
        position: absolute !important;
        bottom: 5% ;
        left: 60% !important;
        right: 0% !important;
    }
    .GetLocation a , .BtnContact a {
        font-size: 3vw;
        line-height: 7.5vw;
    }
    
/********** form ************/

    .form {
        display: none;
    }
    

}
@media (max-width:800px) {
    .workcontainerResp {
        height: 100% !important;
    }
}
*{
    margin: 0 ;
    padding: 0 ;
    box-sizing: border-box ;

}
.test-contact-container{
    width : 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #28223b;
}
.workcontainerResp{
    height: 100%;
}
.work-section-1 {
    width : 100%;
    height: 100vh;
    background-color: #000;
    background: url(/static/media/Ima2.0989c48d.png) no-repeat;
    background-size: cover;
    object-fit: contain
}

.work-section-1-shadow {
    width : 100%;
    height: 100vh;
    padding-left: 6vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center
}

.work-section-1-shadow p  {
    font-size: 5vw;
    color : #FFF;
    font-family: MaisonNeue-Book;
    font-weight: bold
}

.work-section-2{
    width : 100%;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction : column;
            flex-direction : column;
    -webkit-justify-content : center;
            justify-content : center;
    -webkit-align-items : center;
            align-items : center;
    background-color: #28223b;
}

.work-section-2-intro {
    width: 100%;
    height: 407px;
    padding-left: 6.2vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center
}
.work-section-2-intro p{
    font-size: 40px;
    color : #FFF;
    width: 75%;
    opacity: .6;
    line-height: 49px;
    font-family: MaisonNeue;
}

.work-section-2-bottom-space{
    height : 100px;
    width : 100%
}

.contact-bg-container{
    width: 100%;
    height: 100vh;
    display : -webkit-flex;
    display : flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background : url(/static/media/contact-bg.c2c42e90.png) no-repeat;
    background-size: cover;
    position:   relative;
}
.contact-container{
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    top: 0;
    left: 0;
}


.contact-bg-bottom {
    width: 100%;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
}
.contact-bg-bottom div {
    width : 100%;
    height: 35px;
    border-top: 1px solid rgba(255,255,255,.1);
    z-index: 10;
}
.contact-bg-bottom span {
    width: 22%;
    position: relative;
    text-align: center;
    opacity: 0.3;
    top:0;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    font-family: MaisonNeue-Mono;
    font-size: .7vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}



@media (max-width:800px) {
    
   
    .contact-bg-bottom span {
        -webkit-transform: translateY(-10%);
                transform: translateY(-10%);
        width: 100%;
        font-size: 2.4vw !important;
    }
    .contact-bg-bottom {
       height: 25PX;
    }
    .work-section-1-shadow p  {
        font-size: 6vw;
    }
}
@media (max-width:500px) {
    
    
    .work-section-2-intro p{
        width: 90%;
        font-size: 20px;
        color : #FFF;
        opacity: .6;
        line-height: 25px;
        font-family: MaisonNeue;
    }
    .work-section-1-shadow p  {
        font-size: 10vw;
    }
}
.WorkProject-container {
    width: 87%;
    height: 600px;
    background-color: #ffffff;
    position: relative;
    overflow:hidden;
    margin-top : 100px;
    padding: 25px;
    -webkit-transition: padding .5s ease-in-out;
    transition: padding .5s ease-in-out;
}

.WorkProject-container:hover {
    padding: 0px;
}

.WorkProject-container:hover  .WorkProject-container-flow-bg {
   -webkit-transform : scale(1.1);
           transform : scale(1.1);
   -webkit-transition : -webkit-transform .5s ease-in-out;
   transition : -webkit-transform .5s ease-in-out;
   transition : transform .5s ease-in-out;
   transition : transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
}
.WorkProject-container:hover  div:last-of-type {
    -webkit-animation : placeDescription .5s ease-in-out both ;
            animation : placeDescription .5s ease-in-out both ;
}



.WorkProject-container-flow {
    width : 100%;
    height : 100%;
    display: inline-block;
    position : relative
}

.WorkProject-container-flow-bg {
    width : 100%;
    height : 100%;
    position: absolute;
    top : 0;
    display: inline-block;
    left : 0;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    background-size : cover ;
    background-repeat : no-repeat;
    min-height : 600px;
}

.WorkProject-container-flow div:last-of-type{
    width : 400px;
    height :100% ;
    position: absolute;
    border-radius: 6px;
    padding: 45px;
    background-color: #ffffff;
    
}

.WorkProject-container-flow div h4{
    margin-bottom: 45px;
    font-size: 30px;
    font-family: MaisonNeue;
    color: #15071f;
    font-weight: bold;
}
.WorkProject-container-flow div p{
  opacity: 0.6;
  font-family: MaisonNeue;
  font-size: 23px;
  font-weight: 300;
  line-height: 28px;
  color: #15071f;
}

@-webkit-keyframes placeDescription{
    0%{
        
        border-radius : 6px
    }
    100%{
      
        border-radius : 0px
    }
    
  }

@keyframes placeDescription{
    0%{
        
        border-radius : 6px
    }
    100%{
      
        border-radius : 0px
    }
    
  }
@-webkit-keyframes testanimation{
    0%{
        padding: 25px;
    }
    100%{
       
        padding: 0;
    }
    
  }
@keyframes testanimation{
    0%{
        padding: 25px;
    }
    100%{
       
        padding: 0;
    }
    
  }


  @media (max-width:800px) {
    
    .WorkProject-container:hover {
     
    }
    .WorkProject-container-flow {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        
    }
    .WorkProject-container {
        padding: 30px;
    }
    .WorkProject-container-flow div:last-of-type{
        
        width: 100%;
        height: 100%;
    }
    .WorkProject-container-flow div h4{
        font-size: 30px;
    }
    .WorkProject-container-flow div p{
      font-size: 24px;
    }
}

@media (max-width:500px) {
    
    .WorkProject-container {
        width: 100%;
        padding: 15px;
        height :500px;
        margin-top: 30px;
    }
    .WorkProject-container-flow div:last-of-type{
        padding: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .WorkProject-container-flow div h4{
        font-size: 25px;
    }
    .WorkProject-container-flow div p{
      font-size: 20px;
    }
}
*{
    margin: 0 ;
    padding: 0 ;
    box-sizing: border-box ;

}

.About-section-1 {
    width : 100%;
    height: 100vh;
    background-color: #000;
    background: url(/static/media/bg_1.c5b2d37c.svg) no-repeat;
    background-size: cover;
    object-fit: contain;
    padding:  0 !important;
}

.About-section-1-shadow {
    width : 100%;
    height: 100vh;
    padding-left: 6vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center
}
.About-section-1-shadow p  {
    font-size: 5vw;
    color : #FFF;
    font-family: MaisonNeue;
    font-weight: bold
}

.About-section-2 {
    width : 100%;
    height: 100vh;
    background-color: #ffffff;
    padding: 0 !important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.About-section-2 .insideDiv{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    /*margin: 0 64px !important;*/
    padding-left: 6.2vw;
}


.About-section-2 span{
   /*display: block;
   width: 220px;
   height: 82px;*/
   background: url(/static/media/logo_111.345f6eb5.png) no-repeat;

   display: block;
   width: 55%;
   height: auto;
   min-height: 500px;
   background-position: 0px;
   background-size: contain;
  /* margin-right: 64px; */

}
.About-section-2 p{
    font-size: 1.6vw;
    color : #15071f;
    font-family: MaisonNeue;
    font-weight: bold;
    line-height: 2.15vw;
    word-break: break-word;
    width: 50%;
}

@media screen and (max-width:1024px) {

    .About-section-2 p {
        width:100% !important;
    }

    .About-section-2 span {
        width:100% !important;
    }

}


@media screen and (max-width:900px) {


    .About-section-2 span {
        width: 100% !important;
        margin-right: 0px;
        min-height: 450px;
        margin-bottom: 0px !important;
    }

    .About-section-2 p{
        font-size: 3vw;
        line-height: 4vw;
    }
    .About-section-2 span{
        margin-bottom: 50px;
        width: 80% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
     
     }
     .About-section-1-shadow p  {
        font-size: 7vw;
    }

}


@media (max-width:900px) {
    
    .About-section-2 .insideDiv{
        width: 90%;
        display: -webkit-flex;
        display: flex;
        height: 80%;;
        -webkit-flex-direction: column;;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 auto !important;
    }
    .About-section-2 p{
        font-size: 3vw;
        line-height: 4vw;
    }

    .About-section-2 span {
        margin-bottom: 50px;
        width: 65% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
    }
}

@media screen and (max-width: 850px){
    .About-section-2 span {
        margin-bottom: 50px;
        width: 80% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
    }
}




@media (max-width:700px) {
    
    .About-section-2 .insideDiv{
        width: 90%;
        display: -webkit-flex;
        display: flex;
        height: 80%;;
        -webkit-flex-direction: column;;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin: 0 auto !important;
    }
    .About-section-2 p{
        font-size: 3.5vw;
        line-height: 4.5vw;
    }
    .About-section-2 span{
        margin-bottom: 50px;
        width: 80% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
     
     }
     .About-section-1-shadow p  {
        font-size: 7vw;
    }
}


.AboutSection-container {
    width : 100%;
    height: 100vh;
    padding:  0 !important;
    position :relative;
    background-size: cover !important;
    object-fit: contain !important;
    background-position: center !important;
}

.AboutSection-container-description{
    width : 100%;
    height: 100vh;
    padding-left:6.2vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.AboutSection-container-description h4{
    font-family: MaisonNeue-Demi;
    font-size: 2.25vw;
    font-weight: 300;
    line-height: 28px;
    color: #ffffff;
}
.AboutSection-container-description span{
    opacity: 0.6;
    font-family: MaisonNeue;
    font-size:1.6vw;
    font-weight: 300;
    line-height: 45px;
    color: #ffffff;
}
.AboutSection-container-description p{
    width : 45%;
    opacity: 0.6;
    font-family: MaisonNeue-light;
    font-size: 1.8vw;
    line-height: 28px;
    color: #ffffff;
    margin-top : 25px;
}


@media (max-width:1024px) {
    .AboutSection-container-description h4{
        font-size: 35px;
    }
    .AboutSection-container-description span{
        font-size:18px;
    }
    .AboutSection-container-description p{
        font-size: 20px;
        width: 80%;
        line-height: 25px;
    }

    
}

@media (max-width:900px) {
    .AboutSection-container-description h4{
        font-size: 35px;
    }
    .AboutSection-container-description span{
        font-size:18px;
    }
    .AboutSection-container-description p{
        font-size: 20px;
        width: 80%;
        line-height: 25px;
    }

    
}

*{
    margin: 0 ;
    padding: 0 ;
    box-sizing: border-box ;

}
.ProjectDetail-section-1 {
    width : 100%;
    height: 100vh;
    background-color: #000;
    background: url(/static/media/project-1.96935300.png) no-repeat;
    background-size: cover;
    object-fit: contain;
    padding-left: 6.2vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}
.ProjectDetail-section-1-shadow{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #28223b;
    opacity: .6;
    z-index: 1;
}
.ProjectDetail-section-1 p{
    font-size: 5vw;
    color : #FFF;
    font-family: MaisonNeue;
    font-weight: bold;
    z-index: 10;
}
.ProjectDetail-section-1 p span{
    display : block;
    font-size: 2vw;
}

.hero__scroll {
    position: absolute;
    bottom: 50px;
    width: 80px;
    margin: auto;
    display: block;
    cursor: pointer;
    padding-bottom: 40px;
    left: 0;
    right: 0;
    text-transform: uppercase;
    z-index: 20;
    text-align: center;
}

.hero__scroll .chevron {
    margin-top: 20px;
    display: block;
    margin: 0 auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    color: #ffffff;
}
.chevron.botttom:before {
    top: 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    vertical-align: top;
    width: 20px;
}

.ProjectDetail-section-2 {
    width : 100%;
    height: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 6.2vw;
    background-color: #ffffff;
}
.ProjectDetail-section-2 p{
    width: 70%;
    font-size: 2.1vw;
    color : #28223b;
    font-family: MaisonNeue-Book;
}

.ProjectDetail-section-3{
    width:100%;
    height: auto;
    background-color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
}


.contain{
    background-size: contain !important ;
}

.ProjectDetail-type1,.ProjectDetail-type2{
    width: 100%;
    height: 74vh;
    display: -webkit-flex;
    display: flex; 
    -webkit-justify-content: center; 
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.ProjectDetail-type1{
    -webkit-flex-direction: row;
            flex-direction: row; 
}
.ProjectDetail-type2{
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse; 
}

.ProjectDetail-description{
    width: 50%;
    height: 100%;
    padding: 6.2vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.ProjectDetail-description h4{
    font-size: 40px;
    color : #28223b;
    font-family: MaisonNeue-Book;
}
.ProjectDetail-description p{
    font-size: 30px;
    margin-top: 25px;
    color : #28223b;
    font-family: MaisonNeue-Book;
}
.ProjectDetail-image{
    width: 50%;
    height: 100%;
    background-size: cover ;
    background-repeat : no-repeat !important;
    background-position: center ;
    object-fit: contain !important;
    
}

.ProjectDetail-section-4 {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    background-color: #ffffff;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.ProjectDetail-section-4-mobile-container{
    width: 33%;
    height: 95%;
    border-radius: 5%;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 15px 20px -20px #111;
    margin : 0 auto;
}



.ProjectDetail-section-4-img-ctn{
    width: 138%;
    height: 104%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    z-index: 10;
}


.ProjectDetail-section-4-img-1{
    width: 87%;
    height: 95%;
    position: absolute;
    overflow: hidden;
    left: 50%;
    top:2%;
    -webkit-transform: translate(-50%,0%);
            transform: translate(-50%,0%);
    z-index: 2;
}
.ProjectDetail-section-4-img-1 img{
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top:22px;
    -webkit-transform: translate(-50%,0%);
            transform: translate(-50%,0%);
    z-index: 2;
}
/* .ProjectDetail-section-4-img-1{
    width: 87%;
    height: auto;
    position: absolute;
    left: 50%;
    top:22px;
    transform: translate(-50%,0%);
    z-index: 2;
} */
.my_fake_element {
    width: 1px;
    height: 1px ;
}

.animateScroll {
    -webkit-animation: image-scroll 10s 1.1s infinite ease-in-out ;
            animation: image-scroll 10s 1.1s infinite ease-in-out ;
}


@-webkit-keyframes pulse {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}


@keyframes pulse {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-webkit-keyframes image-scroll {
    0% {
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
    25% {
        -webkit-transform: translate(-50%,-70%);
        transform: translate(-50%,-70%);
    }
    50% {
        -webkit-transform: translate(-50%,-65%);
        transform: translate(-50%,-65%);
    }
    75% {
        -webkit-transform: translate(-50%,-55%);
        transform: translate(-50%,-55%);
    }
    90%{
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
    100%{
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
}

@keyframes image-scroll {
    0% {
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
    25% {
        -webkit-transform: translate(-50%,-70%);
        transform: translate(-50%,-70%);
    }
    50% {
        -webkit-transform: translate(-50%,-65%);
        transform: translate(-50%,-65%);
    }
    75% {
        -webkit-transform: translate(-50%,-55%);
        transform: translate(-50%,-55%);
    }
    90%{
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
    100%{
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
}

/*
.ProjectDetail-section-4-mobile-container {
    width: 282px;
    height: 553px;
    border-radius: 46px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 15px 20px -20px #111;
    margin: 0 auto;
}


.ProjectDetail-section-4-img-ctn {
       width: 383px;
    height: 572px;
    position: absolute;
    top: 0;
    left: -51px;
    z-index: 10;
}
*/

/********* RESPONSIVE *******/
@media (max-width:1000px ) {
    /* .ProjectDetail-section-4-mobile-container {
        width: 282px;
        height: 553px;
        border-radius: 46px;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 15px 20px -20px #111;
        margin: 0 auto;
    }
    
    
    .ProjectDetail-section-4-img-ctn {
           width: 383px;
        height: 572px;
        position: absolute;
        top: 0;
        left: -51px;
        z-index: 10;
    } */
   
    .ProjectDetail-type1,.ProjectDetail-type2{
        height: 64vh;
    }
    .ProjectDetail-description{
        width: 43%;
    }
    .ProjectDetail-description h4{
        font-size: 30px;
        color : #28223b;
        font-family: MaisonNeue-Book;
    }
    .ProjectDetail-description p{
        font-size: 25px;
        margin-top: 25px;
        color : #28223b;
        font-family: MaisonNeue-Book;
    }
    .ProjectDetail-image{
        width: 57%;
        height: 100%;
        background-size: cover ;
        background-repeat : no-repeat !important;
        background-position: center ;
        object-fit: contain !important;
        
    }
}
@media (max-height:700px) {
     .ProjectDetail-section-4-mobile-container {
        
        border-radius: 20px;
    }
    
}
@media (min-height:900px) {
    
}
@media (max-width:860px) {
    
    .ProjectDetail-type1{
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 100vh; 
    }
    .ProjectDetail-type2{
        -webkit-flex-direction: column;
                flex-direction: column; 
        height: 80vh;
    }
    .ProjectDetail-description{
        width: 80%;
        height: 45%;
    }
    .ProjectDetail-image{
        width: 100%;
        height: 55%;
        background-size: cover ;
        background-repeat : no-repeat !important;
        background-position: center ;
        object-fit: contain !important;
        
    }
    
}
@media (max-width:540px) {
   
    
    .ProjectDetail-section-4-mobile-container{
        width: 48%;
        height: 98%;
        border-radius: 5%;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 15px 20px -20px #111;
        margin : 0 auto;
    }

    .ProjectDetail-section-2 {
        height: 30vh;
    }
    .ProjectDetail-section-2 p{
        width: 90%;
        font-size: 4.1vw;
        color : #28223b;
        font-family: MaisonNeue-Book;
    }
  

    .ProjectDetail-description {
        padding: 1vw;
    }
    .ProjectDetail-description h4{
        font-size: 20px;
    }
    .ProjectDetail-description p{
        font-size: 15px;
    }
    .ProjectDetail-section-1 p{
        font-size: 12vw;
        color : #FFF;
    }
    .ProjectDetail-section-1 p span{
        font-size: 5.5vw;
        color : #FFF;
    }
}
