.div{
    width: 100%;
    height: 100%;
    padding-left: 4.2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
} 
.div h1{
    font-family: MaisonNeue;
    font-size: 5vw;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    overflow: hidden;
}
.fixed{ 
    display: block;
    overflow: hidden;
    height: auto;
 }
.fixed span{ 
    transform: translateY(100%);
    opacity: 1;
    height: auto;
}

.position-reverse {
    transform: translateY(-100%);
    opacity: .25;
}
.expertise-animate {
    animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    animation-fill-mode: both;
}
.expertise-animate-2 {
    animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    animation-fill-mode: both;
    animation-delay: .15s;
}
.expertise-animate-3 {
    animation: expertise-animate 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    animation-fill-mode: both;
    animation-delay: .3s;
}
.expertise-animate-reverse{
    animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    animation-fill-mode: both;
}
.expertise-animate-reverse-2{
    animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    animation-fill-mode: both;
    animation-delay: .15s;
}
.expertise-animate-reverse-3{
    animation: expertise-animate-reverse 1s cubic-bezier(0.03, 0.71, 0.42, 1) ;
    animation-fill-mode: both;
    animation-delay: .3s;
}
.fixed span.text-flip  {
    display: inline-block;
    
    animation: text-flip 2s cubic-bezier(0.14, 0.6, 0.24, 1) , text-opacity 1.25s cubic-bezier(0.14, 0.6, 0.24, 1);
    animation-fill-mode:forwards;
}
.fixed span.text-flip-reverse {
    display: inline-block;
    
    animation: text-flip-reverse 2s cubic-bezier(0.14, 0.6, 0.24, 1) , text-opacity 1.25s cubic-bezier(0.14, 0.6, 0.24, 1);
    animation-fill-mode:forwards;
}

.div p.paragraph-disapear {
    animation: paragraphe 1s ease-in-out forwards;
    animation-delay: .8s;
}
.home-1 p,.home-2 p{
    width: 30%;
    margin:30px 0; 
    opacity: 0;
    font-family: MaisonNeue-Light;
    font-size: 1.2vw ;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}


.bgImg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: 60vw 37vw !important;
    background-size: auto;
    background-repeat: no-repeat !important;
    background-position: right 0px bottom 0px !important; 
    
}

.animateBg{
    animation: slide 1.2s ease-in-out ;
    animation-fill-mode: forwards;
}



/**************** Expertise Home *****************/
.home-3 {
    padding: 60px 35px 60px 35px !important;
}
.home-3 .div {
    padding: 0 !important;
    display: flex;
    align-items: center;
}
.Expertise{
    width: 91%;
    margin:  0 auto;
    height: 36vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Expertise .Exp_block{
    width: 33.33%;
    height: 50%;
    padding: 7%;
    
    position: relative;

}

.exp_block_hovered span.top-left,
.exp_block_hovered span.bottom-right,
.exp_block_hovered span.bottom-left,
.exp_block_hovered span.top-right{
    width: calc(100% + 4px) !important;
    height: calc(100% + 3px) !important;
    transition: width 2s cubic-bezier(.16,.43,.42,.84) , height 2s cubic-bezier(.16,.43,.42,.84) !important;
}
.exp_block_hovered span.interface_top,
.exp_block_hovered span.marketing_bottom{
    width: calc(100% + 4px) !important;
    transition: width 2s cubic-bezier(.16,.43,.42,.84) , height 2s cubic-bezier(.16,.43,.42,.84) !important;
}

.Exp_block span {
    position: absolute;
    height: 16px;
    width: 16px;
    opacity: 1;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    
}

.Exp_block .top-left{
    left: -2px;
    top: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.Exp_block .top-right{
    right: -2px;
    top: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.Exp_block .bottom-left{
    left: -2px;
    bottom: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}
.Exp_block .bottom-right{
    right:  -2px;
    bottom: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.Exp_block:hover span {
    width: calc(100% + 4px);
    height: calc(100% + 3px);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}

.Interface:hover span.bottom-left , .Interface:hover span.bottom-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.Marketing:hover span.top-left , .Marketing:hover span.top-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.Exp_block:hover span.marketing_bottom {
    width: calc(100% + 3.5px) !important;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}
.Exp_block:hover span.interface_top {
    width: calc(100% + 3.5px) !important;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}
.Exp_block .marketing_bottom{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    bottom: -2px !important;
    right: 50% !important;
    transform: translateX(50%) !important;
}

.Exp_block .interface_top{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    top: -2px !important;
    right: 50% !important;
    transform: translateX(50%) !important;
}
.Expertise h4 { 
    opacity: 0.8;
  font-family: MaisonNeue;
  font-size: 1.1vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 15px;
  transition: 1s;
}
.Expertise div:hover h4{
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.3) 30%, #000 50%, rgba(0,0,0,.3) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
}
@-webkit-keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
    }
}
.Expertise p{ 
    opacity: 0.3;
    font-family: MaisonNeue-Mono;
    font-size: .8vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 1px 0;
    transition: 1s;
}
.Exp_block:hover h4{
    opacity: .9;
    transition: 1s;
}

.Exp_block:hover p{
    opacity: 0.8;
    transition: 1s;
}

.right_bottom {
    border-bottom: 1px solid rgba(255,255,255,.3);
    border-right: 1px solid rgba(255,255,255,.3);
}
.right {
    
    border-right: 1px solid rgba(255,255,255,.3);
}
.bottom {
    border-bottom: 1px solid rgba(255,255,255,.3);
}
/**************** End Expertise Home *****************/

/*************  Animation   ******************/

@keyframes slide {
    from { 
        
        transform: translateX(200px);
         }
    to { 
        
        transform: translateX(0px);
        }
}

@keyframes text-opacity {
    from { 
        opacity: .5;
        }
    to { 
        opacity: 1;
    }
}

@keyframes text-flip {
    0%{
        opacity: 0.2;
        transform: translateY(100%);
    }
    
    100%{
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes expertise-animate {
    0%{
      transform: translateY(300%);
      opacity: 1 !important;
    }
    
    100%{
        transform: translateY(0%);
        opacity: 1 !important;
    }
}
@keyframes expertise-animate-reverse {
    0%{
      transform: translateY(-300%);
    }
    
    100%{
        transform: translateY(0%);
    }
}

@keyframes text-flip-reverse {
    0%{
        opacity: 0.2;
        transform: translateY(-100%);
    }
    
    100%{
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes paragraphe {
    0%{
       opacity: 0;
    }
    100%{
        opacity: 0.5;
    }
}


@keyframes opacity{
    0%{
        opacity: 1;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
}
/*************  End of Animation   ******************/

/********* RESPONSIVE *******/
@media (max-width:1000px) {
    .home-1 h1,.home-2 h1{
        font-size: 6vw;
    }
    .home-1 p,.home-2 p{
        width: 350px;
        font-size: 1.4vw;
    }
    .home-1 .Button,.home-2 .Button{
        width: 120px;
        height: 30px;
        font-size: 1.4vw;
    }
    .home-1 .Button a,.home-2 .Button a{
        line-height: 30px;
    }
    .home-2 .bgImg{
        background-size: 80vw 50vw !important;
    }
}
@media (max-height:700px) {
    .home-1 p{
        margin:3% 0; 
    }
    .home-2 p{
        margin:3% 0; 
    }
}
@media (min-height:900px) {
    .clientsReviews{
        
        height :65% !important;
        
    }
}
@media (max-width:700px) {
    

    /******* EXPERTISE ******/
    .section{
        padding: 0px  !important;
    }
    .Expertise{
        width: 87% !important;
        margin:  0 auto;
        height: 90%;
        position: relative;
        top: 50%;
        
        transform: translateY(-50%) !important;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center !important;
        align-content: flex-start;
    }
    
    .Expertise .Exp_block{
        width: 50% !important;
        height: 30% !important;
       padding: 9% 0% 0% 7% !important;
        border: none !important;
    }
    .Expertise .Exp_block span{
        display: none;
    }
    .Expertise h4 { 
        opacity: 0.8;
        font-family: MaisonNeue;
        font-size: 3vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 15px;
    }
    .Expertise p{ 
        opacity: 0.3;
        font-family: MaisonNeue-Mono;
        font-size: 2.5vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 1px 0;
    }
    .home-1 h1,.home-2 h1{
        font-size: 7vw;
        line-height: 40px;
    }
    .home-1 .div,.home-2 .div{
        padding-left: 12%;
    }
    .home-1 p,.home-2 p{
        width: 70%;
        font-size: 3.2vw;
        margin:8% 0; 
    }
    .home-1 .Button,.home-2 .Button{
        width: 100px;
        font-size: 1.1vw;
    }
    .home-2 .bgImg{
        background-size: 90vw 100vw !important;
    }
}
@media (max-width:500px) {
   
    
    .Expertise{
        width: 100%;
       
    }
}