
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
    overflow: hidden;
    overflow-y: scroll;
}


