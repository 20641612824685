.Header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 500;
}
.header-shadow{
    width: 100%;
    height: 1px ;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: -1px -29px 99px 43px rgba(0,0,0,0.34);
}
.Header-content{
    height: 35px;
    width: 100%;
    display: flex;
    position: relative;
}
.left-log{
    height: 100%;
    width: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    animation: shinny 2s ease-in-out;
}
.Header-content img{
    width: 70px;
    max-width: 70px;
    position: relative;
    top: 100%;
    transform: translateY(-55%) !important;
    margin: 0px 20px;
}
.right-log{
    height: 100%;
    width: calc(100% - 250px);
    
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    animation: shinny 2s ease-in-out;
}
.after-dots{
    height: 100%;
    width: 12px;
    position: absolute;
    right: 0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    animation: shinny 2s ease-in-out;
}
.Header-content nav{
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    transition: .5s;
}
.Header-content ul{
    list-style: none;
   display: none;
   justify-content: space-around;
   align-items: center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%);
   height: 50vh;
   width: 55%;
}
.Header-content ul li{
    width: 220px;
    height: 33px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    
    position: relative;
}

.header-dots {
    width: 30px;
    height: 20px;
    margin: 0 8px;
    position: absolute;
    top: 25px;
    right: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 500;
    cursor: pointer;
}

.header-dots span {
    width: 7px;
    height: 7px;
    background-color: rgba(255, 255, 255, 1) ;
    border-radius: 50%;
    z-index: 150;
    transform: translateY(-100%);
}


.hovered-Header-dots span:first-of-type,.hovered-Header-dots span:last-of-type{
   width: 100%;
   position: absolute;
  display: inline-block;
   border-radius: 5px;
   height: 3px;
   background-color: rgba(255, 255, 255, 1);
   transition: all .1s cubic-bezier(0.23, -0.01, 0.29, 0.12 ) 2s  !important ;
}
.hovered-Header-dots span:first-of-type{
   transform: rotate(45deg) !important;
   animation-fill-mode: unset !important;
   animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 );
}
.hovered-Header-dots span:last-of-type{
    transform: rotate(-45deg) !important;
    animation-fill-mode: unset !important;
    animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 );
}
.hovered-Header-dots span:nth-child(2){
   visibility: hidden;
}

.hovered-Header-dots{
    animation: scale-dots .5s cubic-bezier(0.23, -0.01, 0.29, 0.12 ) !important;
    animation-fill-mode: both !important;
}
a{
    text-align: center;
}
nav a {
    text-decoration: none;
    color: inherit;
    text-align: center;
    line-height:39px; 
    display: inline-block;
}

nav li:hover{
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.3) 30%, #000 50%, rgba(0,0,0,.3) 70%);
    -webkit-mask-size: 200%;
    animation: shine 1.5s infinite;
}
@-webkit-keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
    }
}

.Header-Work , .Header-Expertise , .Header-About , .Header-Contact , .Header-Accueil {
    font-family: MaisonNeue;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.3px;
    text-align: left;
    color: #ffffff;
    background: transparent;
    display: block;
    opacity: 0;
    animation-fill-mode: both !important;
}
.Header-Accueil{
    animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 3s ease forwards;
    animation-delay: .3s;
}
.Header-About{
    animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 1.5s ease forwards;
    animation-delay: .5s;
}

.Header-Expertise{
    animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 2s ease forwards;
    animation-delay: .7s;
}
.Header-Work {
    animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 2.5s ease forwards;
    animation-delay: .9s;
}


.Header-Contact{
    animation: link .8s cubic-bezier(0, 0.6, 0.33, 0.99),
    linkOpacity 3s ease forwards;
    animation-delay: 1.2s;
}



/************** dots animation ******************/
.header-dots span:first-of-type {
    animation: animte-header-dots 1s ease-in-out;
    animation-fill-mode: forwards;
}
.header-dots span:nth-child(2) {
    animation: animte-header-dots 1.5s ease-in-out;
    animation-fill-mode: forwards;
}
.header-dots span:last-of-type{
    animation: animte-header-dots 2s ease-in-out;
    animation-fill-mode: forwards;
}


/***********************************************/

.show-nav{
    
    background-color: rgba(13, 1, 23, .75);
    animation: show-nav .5s ease-in-out;
    animation-fill-mode: forwards;
    transition: .5s;
}


.blur_body{
   
    filter: blur(10px) !important;
    transition: .5s !important;
   /*  transition: all .2s cubic-bezier(0.17, 0.65, 0.38, 0.96) ; */
}



.hide_nav {
    animation: hide-nav .8s ease-in-out;

}
@keyframes show-nav {
    0% {
        height: 0vh;
        opacity: 0;
    }
    1%{
        height: 100vh;
        opacity: 0;
    }
    100%{
        height: 100vh;
        opacity: 1;
    }
}

@keyframes hide-nav {
    0% {
        height: 100vh;
        opacity: 1;
    }
    99%{
        height: 100vh;
        opacity: 0;
    }
    109%{
        height: 0vh;
        opacity: 0;
    }
}


@keyframes animte-header-dots {
    0%{
        opacity: 0;
        transform: translateY(-150%)
    }
    100%{
        opacity: .75;
        transform: translateY(0%);
    }
}
 @-webkit-keyframes animte-header-dots {
    0% { 
        opacity: 0;
        -webkit-transform: translateY(-150%);
     }
   
    100% { 
        opacity: .75;
        -webkit-transform:  translateY(0%); }
} 


@keyframes link {
    from {
        transform: translateY(200%);
        opacity: 0;
    }
    to{
        transform: translateY(0%);
        opacity: 1;
    }
}
@keyframes linkOpacity {
    from {
        
        opacity: 0;
    }
    to{
        
        opacity: .8;
    }
}


@keyframes scale-dots{
    0%{
        transform: scale(1);
        opacity: 0 !important;
    }
    50%{
        transform: scale(5);
        opacity: 1 !important;
    }
    100%{
        transform: scale(1);
        opacity: 1 !important;
    }
}

/*********** RESPONSIVE ************/


@media (max-width:700px) {
    .Header-content{
        height: 20px;
    }
    .Header-content img{
        width: 60px
    }
    .header-dots {
        width: 30px;
       top: 10px;
    }
    .left-log{
        width: 30px; 
    }
    .right-log{
        width: calc(100% - 190px); 
    }
    .after-dots{
        width: 8px;
        
    }
    nav ul{
        flex-direction: column;
        top: 40% !important;
    }
    .hovered-Header-dots{
        top: 29px;
    }
}

@media (max-width:800px) {
    nav ul{
       width: 100% !important;
   }
}
