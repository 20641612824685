*{
    margin: 0 ;
    padding: 0 ;
    box-sizing: border-box ;

}
.ProjectDetail-section-1 {
    width : 100%;
    height: 100vh;
    background-color: #000;
    background: url('../../assets/images/Work/project-1.png') no-repeat ;
    background-size: cover;
    object-fit: contain;
    padding-left: 6.2vw;
    display: flex;
    align-items: center;
    position: relative;
}
.ProjectDetail-section-1-shadow{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #28223b;
    opacity: .6;
    z-index: 1;
}
.ProjectDetail-section-1 p{
    font-size: 5vw;
    color : #FFF;
    font-family: MaisonNeue;
    font-weight: bold;
    z-index: 10;
}
.ProjectDetail-section-1 p span{
    display : block;
    font-size: 2vw;
}

.hero__scroll {
    position: absolute;
    bottom: 50px;
    width: 80px;
    margin: auto;
    display: block;
    cursor: pointer;
    padding-bottom: 40px;
    left: 0;
    right: 0;
    text-transform: uppercase;
    z-index: 20;
    text-align: center;
}

.hero__scroll .chevron {
    margin-top: 20px;
    display: block;
    margin: 0 auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    color: #ffffff;
}
.chevron.botttom:before {
    top: 0;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    vertical-align: top;
    width: 20px;
}

.ProjectDetail-section-2 {
    width : 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    padding-left: 6.2vw;
    background-color: #ffffff;
}
.ProjectDetail-section-2 p{
    width: 70%;
    font-size: 2.1vw;
    color : #28223b;
    font-family: MaisonNeue-Book;
}

.ProjectDetail-section-3{
    width:100%;
    height: auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}


.contain{
    background-size: contain !important ;
}

.ProjectDetail-type1,.ProjectDetail-type2{
    width: 100%;
    height: 74vh;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.ProjectDetail-type1{
    flex-direction: row; 
}
.ProjectDetail-type2{
    flex-direction: row-reverse; 
}

.ProjectDetail-description{
    width: 50%;
    height: 100%;
    padding: 6.2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ProjectDetail-description h4{
    font-size: 40px;
    color : #28223b;
    font-family: MaisonNeue-Book;
}
.ProjectDetail-description p{
    font-size: 30px;
    margin-top: 25px;
    color : #28223b;
    font-family: MaisonNeue-Book;
}
.ProjectDetail-image{
    width: 50%;
    height: 100%;
    background-size: cover ;
    background-repeat : no-repeat !important;
    background-position: center ;
    object-fit: contain !important;
    
}

.ProjectDetail-section-4 {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
}
.ProjectDetail-section-4-mobile-container{
    width: 33%;
    height: 95%;
    border-radius: 5%;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 15px 20px -20px #111;
    margin : 0 auto;
}



.ProjectDetail-section-4-img-ctn{
    width: 138%;
    height: 104%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    z-index: 10;
}


.ProjectDetail-section-4-img-1{
    width: 87%;
    height: 95%;
    position: absolute;
    overflow: hidden;
    left: 50%;
    top:2%;
    transform: translate(-50%,0%);
    z-index: 2;
}
.ProjectDetail-section-4-img-1 img{
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top:22px;
    transform: translate(-50%,0%);
    z-index: 2;
}
/* .ProjectDetail-section-4-img-1{
    width: 87%;
    height: auto;
    position: absolute;
    left: 50%;
    top:22px;
    transform: translate(-50%,0%);
    z-index: 2;
} */
.my_fake_element {
    width: 1px;
    height: 1px ;
}

.animateScroll {
    animation: image-scroll 10s 1.1s infinite ease-in-out ;
}


@keyframes pulse {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes image-scroll {
    0% {
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
    25% {
        -webkit-transform: translate(-50%,-70%);
        transform: translate(-50%,-70%);
    }
    50% {
        -webkit-transform: translate(-50%,-65%);
        transform: translate(-50%,-65%);
    }
    75% {
        -webkit-transform: translate(-50%,-55%);
        transform: translate(-50%,-55%);
    }
    90%{
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
    100%{
        -webkit-transform: translate(-50%,0%);
        transform: translate(-50%,0%);
    }
}

/*
.ProjectDetail-section-4-mobile-container {
    width: 282px;
    height: 553px;
    border-radius: 46px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 15px 20px -20px #111;
    margin: 0 auto;
}


.ProjectDetail-section-4-img-ctn {
       width: 383px;
    height: 572px;
    position: absolute;
    top: 0;
    left: -51px;
    z-index: 10;
}
*/

/********* RESPONSIVE *******/
@media (max-width:1000px ) {
    /* .ProjectDetail-section-4-mobile-container {
        width: 282px;
        height: 553px;
        border-radius: 46px;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 15px 20px -20px #111;
        margin: 0 auto;
    }
    
    
    .ProjectDetail-section-4-img-ctn {
           width: 383px;
        height: 572px;
        position: absolute;
        top: 0;
        left: -51px;
        z-index: 10;
    } */
   
    .ProjectDetail-type1,.ProjectDetail-type2{
        height: 64vh;
    }
    .ProjectDetail-description{
        width: 43%;
    }
    .ProjectDetail-description h4{
        font-size: 30px;
        color : #28223b;
        font-family: MaisonNeue-Book;
    }
    .ProjectDetail-description p{
        font-size: 25px;
        margin-top: 25px;
        color : #28223b;
        font-family: MaisonNeue-Book;
    }
    .ProjectDetail-image{
        width: 57%;
        height: 100%;
        background-size: cover ;
        background-repeat : no-repeat !important;
        background-position: center ;
        object-fit: contain !important;
        
    }
}
@media (max-height:700px) {
     .ProjectDetail-section-4-mobile-container {
        
        border-radius: 20px;
    }
    
}
@media (min-height:900px) {
    
}
@media (max-width:860px) {
    
    .ProjectDetail-type1{
        flex-direction: column;
        height: 100vh; 
    }
    .ProjectDetail-type2{
        flex-direction: column; 
        height: 80vh;
    }
    .ProjectDetail-description{
        width: 80%;
        height: 45%;
    }
    .ProjectDetail-image{
        width: 100%;
        height: 55%;
        background-size: cover ;
        background-repeat : no-repeat !important;
        background-position: center ;
        object-fit: contain !important;
        
    }
    
}
@media (max-width:540px) {
   
    
    .ProjectDetail-section-4-mobile-container{
        width: 48%;
        height: 98%;
        border-radius: 5%;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 15px 20px -20px #111;
        margin : 0 auto;
    }

    .ProjectDetail-section-2 {
        height: 30vh;
    }
    .ProjectDetail-section-2 p{
        width: 90%;
        font-size: 4.1vw;
        color : #28223b;
        font-family: MaisonNeue-Book;
    }
  

    .ProjectDetail-description {
        padding: 1vw;
    }
    .ProjectDetail-description h4{
        font-size: 20px;
    }
    .ProjectDetail-description p{
        font-size: 15px;
    }
    .ProjectDetail-section-1 p{
        font-size: 12vw;
        color : #FFF;
    }
    .ProjectDetail-section-1 p span{
        font-size: 5.5vw;
        color : #FFF;
    }
}