.Paginate {
    width: 35px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
}
.Paginate-dots {
    width: 20px;
    height: 150px;
    position: relative;
    padding: 10px 0px;
    left:0;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.Paginate-dots span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 auto;
    background-color: rgba(255,255,255 ,.2);
    cursor: pointer;
}

.paginate-space-1,.paginate-space-2,.paginate-space-3,.paginate-space-dots-1{
    width: 100%;
    border-left: 1px solid rgba(255,255,255 ,.1);
    animation: shinny 2s ease-in-out;
}
.paginate-space-dots-1{
    height: 22px !important;
}
.paginate-space-dots-2{
    height: 26px !important;
}

.dots-centered{
    position: relative;
    height: calc(100% - 112px) !important;
}
.paginate-space-1{
    height: calc(50% - 75px) !important;
}
.paginate-space-2{
    height:  calc(50% - 75px)!important;
}


.paginate-space-3{
    position: absolute;
    bottom: 0;
    height: 12px !important;
}

#p-triangle2 {
    display: none;
}


 .Paginate-triangle {
    width: 35px;
    height: 35px;
    margin: 10px 0px;
    border-radius: 50%;
    position: relative;
    left: 0;
    bottom: 2px;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}



/****************/


/****************/
.last-dot {
    position: relative;
}
.last-dot::before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(255,255,255 ,1);
}

.selected-dot {
    border: 1px solid white;
    background-color: unset !important;
}

/********** Responsive ************/

@media (max-width:800px) {
    
    .Paginate {
        display: none;
    }
    
}