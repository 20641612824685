.WorkProject-container {
    width: 87%;
    height: 600px;
    background-color: #ffffff;
    position: relative;
    overflow:hidden;
    margin-top : 100px;
    padding: 25px;
    transition: padding .5s ease-in-out;
}

.WorkProject-container:hover {
    padding: 0px;
}

.WorkProject-container:hover  .WorkProject-container-flow-bg {
   transform : scale(1.1);
   transition : transform .5s ease-in-out;
}
.WorkProject-container:hover  div:last-of-type {
    animation : placeDescription .5s ease-in-out both ;
}



.WorkProject-container-flow {
    width : 100%;
    height : 100%;
    display: inline-block;
    position : relative
}

.WorkProject-container-flow-bg {
    width : 100%;
    height : 100%;
    position: absolute;
    top : 0;
    display: inline-block;
    left : 0;
    transition: transform .4s;
    background-size : cover ;
    background-repeat : no-repeat;
    min-height : 600px;
}

.WorkProject-container-flow div:last-of-type{
    width : 400px;
    height :100% ;
    position: absolute;
    border-radius: 6px;
    padding: 45px;
    background-color: #ffffff;
    
}

.WorkProject-container-flow div h4{
    margin-bottom: 45px;
    font-size: 30px;
    font-family: MaisonNeue;
    color: #15071f;
    font-weight: bold;
}
.WorkProject-container-flow div p{
  opacity: 0.6;
  font-family: MaisonNeue;
  font-size: 23px;
  font-weight: 300;
  line-height: 28px;
  color: #15071f;
}

@keyframes placeDescription{
    0%{
        
        border-radius : 6px
    }
    100%{
      
        border-radius : 0px
    }
    
  }
@keyframes testanimation{
    0%{
        padding: 25px;
    }
    100%{
       
        padding: 0;
    }
    
  }


  @media (max-width:800px) {
    
    .WorkProject-container:hover {
     
    }
    .WorkProject-container-flow {
        display: flex;
        justify-content: center;
        
    }
    .WorkProject-container {
        padding: 30px;
    }
    .WorkProject-container-flow div:last-of-type{
        
        width: 100%;
        height: 100%;
    }
    .WorkProject-container-flow div h4{
        font-size: 30px;
    }
    .WorkProject-container-flow div p{
      font-size: 24px;
    }
}

@media (max-width:500px) {
    
    .WorkProject-container {
        width: 100%;
        padding: 15px;
        height :500px;
        margin-top: 30px;
    }
    .WorkProject-container-flow div:last-of-type{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .WorkProject-container-flow div h4{
        font-size: 25px;
    }
    .WorkProject-container-flow div p{
      font-size: 20px;
    }
}