*{
    margin: 0 ;
    padding: 0 ;
    box-sizing: border-box ;

}

.About-section-1 {
    width : 100%;
    height: 100vh;
    background-color: #000;
    background: url('../../assets/images/About/bg_1.svg') no-repeat ;
    background-size: cover;
    object-fit: contain;
    padding:  0 !important;
}

.About-section-1-shadow {
    width : 100%;
    height: 100vh;
    padding-left: 6vw;
    display: flex;
    align-items: center
}
.About-section-1-shadow p  {
    font-size: 5vw;
    color : #FFF;
    font-family: MaisonNeue;
    font-weight: bold
}

.About-section-2 {
    width : 100%;
    height: 100vh;
    background-color: #ffffff;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.About-section-2 .insideDiv{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    /*margin: 0 64px !important;*/
    padding-left: 6.2vw;
}


.About-section-2 span{
   /*display: block;
   width: 220px;
   height: 82px;*/
   background: url('../../assets/images/About/logo_111.png') no-repeat ;

   display: block;
   width: 55%;
   height: auto;
   min-height: 500px;
   background-position: 0px;
   background-size: contain;
  /* margin-right: 64px; */

}
.About-section-2 p{
    font-size: 1.6vw;
    color : #15071f;
    font-family: MaisonNeue;
    font-weight: bold;
    line-height: 2.15vw;
    word-break: break-word;
    width: 50%;
}

@media screen and (max-width:1024px) {

    .About-section-2 p {
        width:100% !important;
    }

    .About-section-2 span {
        width:100% !important;
    }

}


@media screen and (max-width:900px) {


    .About-section-2 span {
        width: 100% !important;
        margin-right: 0px;
        min-height: 450px;
        margin-bottom: 0px !important;
    }

    .About-section-2 p{
        font-size: 3vw;
        line-height: 4vw;
    }
    .About-section-2 span{
        margin-bottom: 50px;
        width: 80% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
     
     }
     .About-section-1-shadow p  {
        font-size: 7vw;
    }

}


@media (max-width:900px) {
    
    .About-section-2 .insideDiv{
        width: 90%;
        display: flex;
        height: 80%;;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto !important;
    }
    .About-section-2 p{
        font-size: 3vw;
        line-height: 4vw;
    }

    .About-section-2 span {
        margin-bottom: 50px;
        width: 65% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
    }
}

@media screen and (max-width: 850px){
    .About-section-2 span {
        margin-bottom: 50px;
        width: 80% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
    }
}




@media (max-width:700px) {
    
    .About-section-2 .insideDiv{
        width: 90%;
        display: flex;
        height: 80%;;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto !important;
    }
    .About-section-2 p{
        font-size: 3.5vw;
        line-height: 4.5vw;
    }
    .About-section-2 span{
        margin-bottom: 50px;
        width: 80% !important;
        margin-right: 0px;
        min-height: 300px;
        margin-bottom: 0px !important;
     
     }
     .About-section-1-shadow p  {
        font-size: 7vw;
    }
}

