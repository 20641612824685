.AboutSection-container {
    width : 100%;
    height: 100vh;
    padding:  0 !important;
    position :relative;
    background-size: cover !important;
    object-fit: contain !important;
    background-position: center !important;
}

.AboutSection-container-description{
    width : 100%;
    height: 100vh;
    padding-left:6.2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.AboutSection-container-description h4{
    font-family: MaisonNeue-Demi;
    font-size: 2.25vw;
    font-weight: 300;
    line-height: 28px;
    color: #ffffff;
}
.AboutSection-container-description span{
    opacity: 0.6;
    font-family: MaisonNeue;
    font-size:1.6vw;
    font-weight: 300;
    line-height: 45px;
    color: #ffffff;
}
.AboutSection-container-description p{
    width : 45%;
    opacity: 0.6;
    font-family: MaisonNeue-light;
    font-size: 1.8vw;
    line-height: 28px;
    color: #ffffff;
    margin-top : 25px;
}


@media (max-width:1024px) {
    .AboutSection-container-description h4{
        font-size: 35px;
    }
    .AboutSection-container-description span{
        font-size:18px;
    }
    .AboutSection-container-description p{
        font-size: 20px;
        width: 80%;
        line-height: 25px;
    }

    
}

@media (max-width:900px) {
    .AboutSection-container-description h4{
        font-size: 35px;
    }
    .AboutSection-container-description span{
        font-size:18px;
    }
    .AboutSection-container-description p{
        font-size: 20px;
        width: 80%;
        line-height: 25px;
    }

    
}
