.socialMedia {
    width: 285px;
    height: auto;
    z-index: 100;
    display: flex;
    justify-content: space-around;
    position: relative;
    top: -14px;
    padding: 0 20px;
    animation: scrolltoSocialMedia 1.5s ease-in;
}
.socialMedia a {
    position: relative;
    margin-right: 5px;

}
.anchorAnimate img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}


.anchorAnimate img:hover{
    border-width: 1px;
    border-style: solid;
    animation: sk-bounce 1s ease forwards;
}


@keyframes sk-bounce {
    0%  { border-color: rgba(255, 255, 255, 0) }
    100% { border-color: rgba(255, 255, 255, .3)  }
  }
  


.animateSocialMediaReverse{
    display: none !important;
}

@keyframes scrolltoSocialMedia {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes animate-social-media {
    from{
        z-index: 100;
        opacity: 0;
    }
    to{
        z-index: 500;
        opacity: 1;
    }
}


/********** Responsive ************/

@media (max-width:700px) {
    
    .socialMedia {
        padding: 0;
        top: -10px !important;
    }
    .socialMedia img{
        width: 22px;
        height: 22px;
    }
}