.reviews{
    width: 50%;
    height: 80%;
    position: relative !important;
    padding-left:8%;
    top: 50%;
    transform: translateY(-50%);
}
 
.review{
    width: 100%;
    height: 70%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}



.review .thumbnail {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.thumbnail h3 {
    font-family: MaisonNeue;
    font-size: 1.5vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.thumbnail span {
    opacity: 0.5;
    font-family: MaisonNeue;
    font-size: 1.2vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.review .about {
    width: 75%;
    height: 50%;
} 

.about p {
   height: auto;
   margin-top: 6% ;
    opacity: 0.3;
    font-family: MaisonNeue-Light;
    font-size: 1vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: #ffffff;
    

}


.about img {
    width: 120px;
    position: absolute;
    bottom: 15%;
}
.review-controls {
    width: 80px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.next , .prev{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}

.next img {
    width: 30px;
    transform: rotate(90deg) !important;
}
.prev img {
    width: 30px;
    transform: rotate(-90deg) !important;
}


.thumbnail_animation {
    animation: paragraph 1s ease;
}
@keyframes paragraph {
    0%{
        transform: translateY(50%);
        opacity: 0.1 !important;
    }
    
    100%{
        transform: translateY(0%);
        opacity: .3 !important;
    }
}

@media (max-width:700px) {
    .reviews{
        width: 100%;
        height: 65%;
        position: relative !important;
        padding-left:5%;
        top: 0;
        transform: translateY(0%);
    }
    .review{
        width: 100%;
        height: 68%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .thumbnail h3 {
        font-size: 4.5vw;
    }
    .thumbnail span {
        font-size: 3vw;
    }
    .review .about {
        width: 95%;
        height: 45%;
    } 
    
    .about p {
        font-size: 3vw;
        height: 100%;
    }
    .review-controls {
        width: 80px;
        height: 30px;
        position: absolute;
        left: 3% !important;
        
    }
    .about img {
        width: 30%;
        position: relative;
    }
}