*{
    margin: 0 ;
    padding: 0 ;
    box-sizing: border-box ;

}
.test-contact-container{
    width : 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #28223b;
}
.workcontainerResp{
    height: 100%;
}
.work-section-1 {
    width : 100%;
    height: 100vh;
    background-color: #000;
    background: url('../../assets/images/Work/Ima2.png') no-repeat ;
    background-size: cover;
    object-fit: contain
}

.work-section-1-shadow {
    width : 100%;
    height: 100vh;
    padding-left: 6vw;
    display: flex;
    align-items: center
}

.work-section-1-shadow p  {
    font-size: 5vw;
    color : #FFF;
    font-family: MaisonNeue-Book;
    font-weight: bold
}

.work-section-2{
    width : 100%;
    height: auto;
    display: flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    background-color: #28223b;
}

.work-section-2-intro {
    width: 100%;
    height: 407px;
    padding-left: 6.2vw;
    display: flex;
    align-items: center
}
.work-section-2-intro p{
    font-size: 40px;
    color : #FFF;
    width: 75%;
    opacity: .6;
    line-height: 49px;
    font-family: MaisonNeue;
}

.work-section-2-bottom-space{
    height : 100px;
    width : 100%
}

.contact-bg-container{
    width: 100%;
    height: 100vh;
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background : url('../../assets/images/Work/contact-bg.png') no-repeat;
    background-size: cover;
    position:   relative;
}
.contact-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
}


.contact-bg-bottom {
    width: 100%;
    height: 35px;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
}
.contact-bg-bottom div {
    width : 100%;
    height: 35px;
    border-top: 1px solid rgba(255,255,255,.1);
    z-index: 10;
}
.contact-bg-bottom span {
    width: 22%;
    position: relative;
    text-align: center;
    opacity: 0.3;
    top:0;
    transform: translateY(-10%);
    font-family: MaisonNeue-Mono;
    font-size: .7vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}



@media (max-width:800px) {
    
   
    .contact-bg-bottom span {
        transform: translateY(-10%);
        width: 100%;
        font-size: 2.4vw !important;
    }
    .contact-bg-bottom {
       height: 25PX;
    }
    .work-section-1-shadow p  {
        font-size: 6vw;
    }
}
@media (max-width:500px) {
    
    
    .work-section-2-intro p{
        width: 90%;
        font-size: 20px;
        color : #FFF;
        opacity: .6;
        line-height: 25px;
        font-family: MaisonNeue;
    }
    .work-section-1-shadow p  {
        font-size: 10vw;
    }
}