.Skeleton{
    width: 100%;
    height: 100vh;
}

.Skeleton-left{
    width: 35px;
    height: 100%;
    position: absolute;
    left: 0;
    border-right: 1px solid rgba(255,255,255,.1);
    animation: shinny 2s ease-in-out;
    z-index: 100;
}

.Skeleton-bottom{
    width: 100%;
    height: 35px;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
}
.Skeleton-bottom .bottom-border{
    
    height: 35px;
    border-top: 1px solid rgba(255,255,255,.1);
    animation: shinny 2s ease-in-out;
    z-index: 10;
}
.bottom-border:first-of-type{
    width: 100px;
    transition: 1s;
}
.bottom-border:last-of-type{
    width: 100%;
}
.border-2{
    border: none !important;
    display: flex;
}
.border-2 div{
    border-top: 1px solid rgba(255,255,255,.1);
   
}
.border-2 div:nth-child(1){
    width: calc(100% - 60px);
}
.border-2 div:nth-child(2){
    width: 42px;
    position: absolute;
    right: 0;
    border: none;
}
.border-2 div:nth-child(3){
    width: 10px;
    position: absolute;
    right: 0;
}
.section {
    padding: 60px 35px 35px 35px;
    position: relative;
}

.Skeleton-bottom span {
    width: 12%;
  position: relative;
  text-align: center;
  opacity: 0.3;
  top:0;
  transform: translateY(-10%);
  font-family: MaisonNeue-Mono;
  font-size: .7vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: none;
  animation: scrolltoSpan 1.5s ease-in;
}

.widthForSpan{

    width: 50% !important;
    transition: 1s;
    
}

@keyframes scrolltoSpan {
    from{
        opacity: 0;
    }
    to{
        opacity: .3;
    }
}



/********** Responsive ************/

@media (max-width:800px) {
    
    .Skeleton-left{
        display: none;
    }
    .Skeleton-bottom{
        height: 20px !important;
    }
    .Skeleton-bottom span {
        
        transform: translateY(-10%);
        width: 50%;
        font-size: 2.4vw !important;
    }
    .bottom-border:first-of-type{
        width: 50%;
        transition: 1s;
    }
    .bottom-border:last-of-type{
        width: 50%;
        border-top: 1px solid rgba(255,255,255,.1) !important; 
    }
    .border-2 div{
        border:none;
       
    }
}