.clients{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*
    padding-left: 2%;
    padding-right: 2%;
    */
}

.clients-slide {
    width: 100%;
    height: 66.66%;
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
    align-content: flex-start;
    align-items: center;
}
.clients-slide div {
    width: 33.33%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.clients-slide div span {
    position: absolute;
    height: 16px;
    width: 16px;
    opacity: 1;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
    
}

.clients-slide div .top-left{
    left: -2px;
    top: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.clients-slide div .top-right{
    right: -2px;
    top: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-top: 1px solid rgba(255,255,255,.2);
}
.clients-slide div .bottom-left{
    left: -2px;
    bottom: -2px; 
    border-left: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}
.clients-slide div .bottom-right{
    right:  -2px;
    bottom: -2px; 
    border-right: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.clients-slide div:hover span {
    width: calc(100% + 4px);
    height: calc(100% + 3px);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   
}
.clients-slide div:hover img{
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.3) 30%, #000 50%, rgba(0,0,0,.3) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
}
.tweak-1:hover span.bottom-left , .tweak-1:hover span.bottom-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.tweak-2:hover span.top-left , .tweak-2:hover span.top-right  {
    width: calc(50% + 2px);
    height: calc(100% + 3px);
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
   /*  animation: opacity 1 cubic-bezier(.16,.43,.42,.84); */
}
.clients-slide div .marketing_bottom{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    bottom: -2px !important;
    left: 50% !important;
    transform: translateX(-50.3%) !important;
}

.clients-slide div .interface_top{
    width: 40px !important;
    height:  1px !important;
    background-color: rgba(255,255,255,.2);
    border: none;
    top: -2px !important;
    left: 50% !important;
    transform: translateX(-50.3%) !important;
}
.clients-slide div:hover span.marketing_bottom {
    width: calc(100% + 3.9px) !important;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}
.clients-slide div:hover span.interface_top {
    width: calc(100% + 3.7px) !important;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}

.clients-slide img {
    width: 100%;
    height: auto;
    opacity: .5;
    transition: 1s;
} 

.clients-slide div:hover img {
    opacity: 1;
    transition: 1s;
}

.slider{
    display: none !important;
}
.slider-list {
    height: 100px !important;
    margin: 0 !important;
}

.carousel-item{
    width: 95% !important;
    height: 100px !important;
    position: absolute !important;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel-item a {
    width: 20vw;
    height: 20vw;
    display: block;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.08);
}
.carousel-item a img{
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#nike,#coca,#zurich,#macdo{
    width: 60% !important;
}
#nike,#zurich{
    transform: translateY(-60%) !important;
}
.img-client{
    width: 80% !important;
}

@media (max-width:700px) {
    .clients-slide{
        display: none !important;
    }
    .slider{
        display: block !important;
        width: 90% !important;
       height: 100% !important;
       margin:  0 auto;
    }
    .clients{
        width: 100%;
        height: 30%;
        border: none;
        padding: 0% !important;
        padding-top: 5% !important;
    }
    
}

@media (min-height:950px){
    .carousel-item{
        height: 180px !important;
    }
    .home-4 .div{
     /* padding: 5% 0% 0% 0%!important; */
    }
}