.home-4 .div{
    padding: 0;
    display: flex;
    align-items: center;
}

.clientsReviews{
    width:91%; 
    height :75%;
    margin: 0 auto;
    display: flex;
}

@media (max-width:700px) {
    .clientsReviews{
        width:80%; 
        height :90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
    }
    .home-4 .div{
        align-items: unset !important;
    }
}