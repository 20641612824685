.home-5 .div{
    padding: 0!important;
    resize: none;
}

.aboutTest{
    background-color: #28223b !important;
}

.onFocus{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative !important;
    top: 50px;
    left: 0 !important;
}
.onBlur{
    width: 100%;
    height: 100%;
    padding-left: 5% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
}


.selectedText {
    -webkit-text-fill-color: rgba(248, 247, 247, 0.8) !important;
}
.contact{
    width: 100%;
    margin: 0 auto;
    height: 28vw;
    display: flex;
}
.in-Touch{
    width: 50%;
    height: 100%;
    padding-left: 4.2%;
    position: relative;
}

.in-Touch h1{
  font-family: MaisonNeue;
  font-size: 3.9vw;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.info span{
  font-family: MaisonNeue;
  font-size: 1.4vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  opacity: 0;
}
.in-Touch p{
  width: 100%;
  opacity: 0.5;
  font-family: MaisonNeue;
  font-size: 1vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 10px 0;
  opacity: 0;
}
.in-Touch .info{
    margin: 5% 0;
}
.in-Touch .GetLocation{
    position: absolute !important;
    bottom: 3px !important;
    left: 9%;
    opacity: 0;
    
}
.in-Touch .BtnContact{
    display: none !important;
    position: absolute !important;
    bottom: 3px !important;
    left: 40%;
    opacity: 0;
    
}

/********** form ************/

.form {
    width: 50%;
    height: 100%;
    padding-left: 8%;
    padding-top: 2%;
    position: relative;
    
}

.form .Button{
    position: absolute !important;
    bottom: 1px !important;
    left: 1px;
    margin-left: 16.5%;
    opacity: 0;
}
.form-control label{
    font-family: MaisonNeue;
    font-size:1.2vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: block;
    opacity: 0;
}
.form-control input, .form-control textarea{
    width: 80%;
    height: 30px;
    outline: none;
    margin-top: 1.2%;
    margin-bottom: 4%;
    border: none;
    background: transparent !important;
    border-bottom: 1px solid rgba(255,255,255,.1);
    opacity: 0;
    font-family: MaisonNeue;
    font-size: 1vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    -webkit-text-fill-color: rgba(248, 247, 247, 0.2);
    caret-color : rgba(248, 247, 247, 0.76);
}
/* input:focus , textarea:focus{
    background-color: transparent !important;
    color: transparent !important;
} */
input:focus::-webkit-input-placeholder{
    color: transparent!important;
}
input:focus::-moz-placeholder{
    color: transparent!important;
}
input:focus:-moz-placeholder{
    color: transparent!important;
}
.form-control textarea{
    height: 6.5vw;
    resize: none;
}

.animate-intouch * {
    animation-fill-mode: both !important;
}


.animate-intouch .info > span {
    animation: button .7s ease-in-out ;
    animation-delay: .2s;
}
.animate-intouch .info > p {
    animation: animate-p-inTouch .7s ease-in-out ;
    animation-delay: .5s;
}

.animate-intouch .Button {
    animation: button 1s ease-in-out;
    animation-delay: 1s;
}

.animate-form * {
    animation-fill-mode: both !important;
}
.animate-form .form-control:first-of-type label{
    animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out ;
    animation-delay: 0.1s;
}
.animate-form .form-control:first-of-type input{
    animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
    animation-delay: 0.15s;
}
.animate-form .form-control:nth-child(2) label{
    animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
    animation-delay: 0.2s;
}
.animate-form .form-control:nth-child(2) input{
    animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out  ;
    animation-delay: 0.25s;
}
.animate-form .form-control:nth-child(3) label{
    animation: animateForm .5s cubic-bezier(0.14, 0.6, 0.24, 1),button 1.5s  ease-in-out ;
    animation-delay: 0.3s;
}
.animate-form .form-control:nth-child(3) textarea{
    animation: animateForm .4s cubic-bezier(0.14, 0.6, 0.24, 1) ,button 1.5s  ease-in-out;
    animation-delay: 0.35s;
}

.animate-form .Button {
    animation: button 1s ease-in-out;
    animation-delay: 1s;
}

/********** Animation ***********/
@keyframes animate-p-inTouch {
    from{
        opacity: 0;
    }
    to{
        opacity: 0.5;
    }
}
@keyframes animate-inTouch {
    from{
        opacity: 0;
        transform: translateY(2000%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes animateForm {
    from{
        opacity: 0;
        transform: translateY(2000%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes button {
    form{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

/********* RESPONSIVE *******/

 
@media (max-width:800px) {
    .contact{
        flex-direction: column;
        height: 95%;
        width: 95%;
        padding-left:4%!important;
        overflow: unset !important;
    }
    
    .in-Touch{
        width: 95%;
        height: 70%;
        top: 50%;
        transform: translateY(-50%);
    }
    .in-Touch h1{
        font-size: 12vw;
    }
    .info{
        width: 100%;
        margin-top: 10% !important;
    }
    .info span{
        font-size: 6.5vw;
    }
    .info p{
        font-size: 3.5vw;
    }
    .in-Touch .GetLocation{
        width: 28vw !important;
        height: 7.5vw !important;
        position: absolute !important;
        bottom: 5% ;
        left: 5% !important;
    }
    .in-Touch .BtnContact{
        display: block !important;
        width: 28vw !important;
        height: 7.5vw !important;
        position: absolute !important;
        bottom: 5% ;
        left: 60% !important;
        right: 0% !important;
    }
    .GetLocation a , .BtnContact a {
        font-size: 3vw;
        line-height: 7.5vw;
    }
    
/********** form ************/

    .form {
        display: none;
    }
    

}
@media (max-width:800px) {
    .workcontainerResp {
        height: 100% !important;
    }
}