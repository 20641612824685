.Button{
    width: 9vw;
    height: 2.5vw;
    border: 1px solid rgba(255, 255, 255, .1);
    
    position: relative;
}

.Button a {
    text-decoration: none;
    font-family: MaisonNeue;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
line-height: 2.5vw;
position: absolute;
top: 0;
left: 0;
cursor: pointer;
}


.Button span {
    position: absolute;
    height: 10px;
    width: 10px;
    transition: width .5s cubic-bezier(.16,.43,.42,.84) , height .5s cubic-bezier(.16,.43,.42,.84);
}

.Button .top-left{
    left: -2px;
    top: -2px; 
    border-left: 1px solid rgba(255, 255, 255, .3);
    border-top: 1px solid rgba(255, 255, 255, .3);
}
.Button .top-right{
    right: -2px;
    top: -2px; 
    border-right: 1px solid rgba(255, 255, 255, .3);
    border-top: 1px solid rgba(255, 255, 255, .3);
}
.Button .bottom-left{
    left: -2px;
    bottom: -2px; 
    border-left: 1px solid rgba(255, 255, 255, .3);
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}
.Button .bottom-right{
    right:  -2px;
    bottom: -2px; 
    border-right: 1px solid rgba(255, 255, 255, .3);
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.Button:hover span {
    width: 100%;
    height: 100%;
    border-color: rgba(255, 255, 255, 1);
    transition: width 1s cubic-bezier(.16,.43,.42,.84) , height 1s cubic-bezier(.16,.43,.42,.84);
}

@media (max-width:500px) {
    .Button span{
        width: 7px;
        height: 7px;
    }
}