@font-face {
  font-family: MaisonNeue;
  src: url('./assets/fonts/Maison_Neue/Maison_Neue/medium.otf');
}
@font-face {
  font-family: MaisonNeue-Mono;
  src: url('./assets/fonts/Maison_Neue/Maison_Neue/mono.otf');
}
@font-face {
  font-family: MaisonNeue-Light;
  src: url('./assets/fonts/Maison_Neue/Maison_Neue/light.otf');
}
@font-face {
  font-family: MaisonNeue-Demi;
  src: url('./assets/fonts/Maison_Neue/Maison_Neue/demi.otf');
}
@font-face {
  font-family: MaisonNeue-Book;
  src: url('./assets/fonts/Maison_Neue/Maison_Neue/book.otf');
}
body {
    width: 100%;
    background-color: #10041E !important;
    height: 100vh;
    background: url('./assets/images/bg.png') ;
    position: relative;
    background-size: 100% ;
    transition: background-position 20s cubic-bezier(0, 0.57, 0.43, 1.01);
}

.section .fp-tableCell{
  height: 100% !important;
}


.loaded-logo{
  width: 100%;
  height: 100vh;
  background-color: #10041E;
  z-index: 800;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.loaded-logo > .dg {
  display: none;
}

@keyframes zoomout{
  0%{
    background-size: 200%;
  }
  100%{
    background-size: 100%;
  }
  
}

@media (min-width:800px) {
  .animate-body{
    animation: zoomout 15s cubic-bezier(0.17, 0.59, 0.4, 0.99) both;
  }
}

@media (max-width:800px) {
  body{
    background: url('./assets/images/Website-Icons/bg_mobile.png')  ;
    background-size: 100% ;
  }
}

/*********************/

.loaded-logo {
  background-color: #10041E;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  border-top: 2px solid red 
}

html .slides,
.loaded-logo .slides,
html > .dg,
.loaded-logo > .dg {
  display: none;
}

@media (min-width: 1000px) {
 
  .loaded-logo > .dg {
    display: block;
  }
}
